
@font-face {
    font-family: "SF Pro Display Bold";
    src: url("Fonts/SF Pro Display Bold.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "SF Pro Display Medium";
    src: url("Fonts/SF Pro Display Medium.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}

:root {
    --BODY_COLOR: rgb(242 241 246);

    --AIR_COLOR: rgb(255 255 255 / 90%);
    --AIR_BLOCK_COLOR: rgb(195 191 209 / 30%);
    --AIR_BLUR: saturate(180%) blur(20px);
    --AIR_SHADOW: 0px 3px 10px -3px rgb(114 114 114 / 50%);

    --BLOCK_COLOR: rgb(254 254 254);
    --BLOCK_COLOR_LITE: rgb(254 254 254 / 55%);
    --BLOCK_COLOR_LITE_TEXT: rgb(136 134 141);
    --BLOCK_BLOCK_COLOR: rgb(243 242 246);
    --BLOCK_BLOCK_COLOR_LITE: rgb(243 242 246 / 60%);
    --BLOCK_SHADOW: 0px 1px 2px -1px rgb(219 218 225);

    --BTN_BACKGROUND: rgb(243 242 246);
    --BTN_TEXT_COLOR: rgb(112 111 124);
    --BTN_CLOSE_COLOR: rgb(255 193 193 / 70%);

    --INTERACTION_BTN_COLOR: rgb(244 243 246);
    --INTERACTION_BTN_TEXT_COLOR: rgb(83 76 93 / 65%);

    --INPUT_COLOR: rgb(243 242 246);

    --ACCENT_COLOR: #9674ca;
    --GRADIENT_SHADOW: 0px 0px 9px -1px rgb(154 112 199 / 67%);
    --NL_GRADIENT: linear-gradient(20deg, rgb(127 110 176), rgb(161 157 177));
    --GOLD_GRADIENT: linear-gradient(465deg, #fab31e, #fd9347);

    --TITLE_COLOR: rgb(81 78 88);
    --TEXT_COLOR: rgb(97 93 106);
    --TEXT_COLOR_LITE: rgb(139 135 154 / 50%);

    --FS_TITLE: 1em;
    --FS_TEXT: 0.92em;
    --FS_NAME: 1em;
    --FS_D_OR_P: 0.8em;

    --FF_TITLE: SF Pro Display Bold;

    --C_TOP_MARGIN: 80px;
    --BR_BASE: 10px/9px;
    --PUSTOTA_WIDTH: 3px;
    --D_OR_P_COLOR: rgb(104 101 112 / 70%);

    --REPLY_BG: rgb(150 116 202 / 20%);
    --REPLY_SVG_COLOR: rgb(150 116 202 / 50%);

    --CODE_BG: #d1d1d1;
    --CODE_COLOR: black;
}

body {
    background: var(--BODY_COLOR);
    color: var(--TEXT_COLOR);
    font-family: SF Pro Display Medium;
    font-size: var(--FS_TEXT);
    letter-spacing: 0.019em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    transition: .4s;
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

*, *::before, *::after {
    box-sizing: border-box;
    scrollbar-width: none;
}

input:not([type="checkbox"]),
textarea,
select,
button {
    font-size: var(--FS_TEXT);
    font-family: inherit;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a {
    color: var(--ACCENT_COLOR);
}

input[type="radio"] {
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
    width: auto;
    max-width: auto;
}
input[type="text"], input[type="email"], input[type="password"], textarea {
    background: var(--INPUT_COLOR);
    color: var(--TEXT_COLOR);
    font-size: var(--FS_TEXT);
    border-radius: 8px;
    border: 0;
    outline: 2px solid rgb(178 171 206 / 0%);
    outline-offset: 4px;
    transition: .2s;
}
input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder, 
input[type="password"]::-webkit-input-placeholder, 
textarea::-webkit-input-placeholder {
    color: rgb(60 60 74 / 70%);
}
input[type="text"]:hover, 
input[type="text"]:focus,
input[type="email"]:hover, 
input[type="email"]:focus,
input[type="password"]:hover, 
input[type="password"]:focus,
textarea:hover, 
textarea:focus {
    outline: 2px solid rgb(243 242 246 / 80%);
    outline-offset: 2px;
}

a {
    text-decoration: none;
}

button {
    background: var(--BTN_BACKGROUND);
    color: var(--BTN_TEXT_COLOR);
    border-radius: var(--BR_BASE);
    font-size: 0.93em;
    border: none;
    cursor: pointer;
    user-select: none;
    margin: 0em;
    padding-block: 1px;
    padding-inline: 6px;
}
.UI-ActiveButton {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    transition: 0.1s;
    outline: solid 2px rgb(150 116 202 / 0%);
    outline-offset: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.UI-ActiveButton:hover {
    outline: solid 2px rgb(150 116 202 / 50%);
    outline-offset: 2px;
}
button:active,
active_button:active {
    filter: brightness(0.8);
}

::-webkit-scrollbar {
    display: none;
}

eball {
    background: linear-gradient(37deg, #623b9e, #da8aff);
    color: #fff;
    border-radius: 100%;
    border: 1px solid #9b61cc;
    width: 30px;
    height: 30px;
    text-shadow: 1px 1px 10px #41105c;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Глобально */

.UI-Blur {
    background: rgb(119 119 119 / 50%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 10;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0; 
    visibility: hidden;
}

.Avatar {
    background: var(--NL_GRADIENT);
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    user-select: none;
    line-height: 0;
    outline: 2px solid rgb(178 171 206 / 0%);
    outline-offset: 4px;
    transition: .1s;
}
.Avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Avatar .UI-PRELOAD {
    width: 100%;
    height: 100%;
}

.NonAvatar {
    color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Интерфейс */

/* Интерфейс - Логотип */

header {
    background: var(--AIR_COLOR);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
    box-shadow: 0px 2px 12px -9px rgb(112 112 127);
    -moz-box-shadow: 0px 2px 12px -9px rgb(112 112 127);
    -webkit-box-shadow: 0px 2px 12px -9px rgb(112 112 127);
}
.UI-N_DIV {
    width: 100%;
    max-width: 1045px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.UI-N_DIV .AvatarContainer {
    position: relative;
    flex-shrink: 0;
}
.UI-N_DIV .Avatar {
    width: 40px;
    height: 40px;
    margin: 0px;
    cursor: pointer;
    font-size: 20px;
    z-index: 2;
}
.UI-N_DIV .Avatar:active, .Logo:active {
    filter: brightness(0.8);
}
.UI-N_DIV .UI-NCounter {
    bottom: 0;
    left: 0;
}

.UI-Logo {
    width: 40px;
    height: 40px;
    margin: 0px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    background-image: url("../Images/Logo.svg");
    background-size: cover;
}
.UI-Logo:active {
    filter: brightness(0.8);
}

.Search-Container {
    width: 100%;
    max-width: 500px;
    height: 34.7px;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
}
.Search-Container input {
    background: var(--AIR_BLOCK_COLOR);
    padding: 0px 12px;
    flex: 1;
}
.Search-Container input:hover, 
.Search-Container input:focus {
    outline: 2px solid var(--AIR_BLOCK_COLOR);
}
.Search-Container .EBalls {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    margin-left: 10px;
    padding: 5px;
    user-select: none;
    display: none;
    align-items: center;
}
.Search-Container .EBalls eball {
    transform: scale(0.8);
}
.Search-Container .EBalls .Count {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 0.9em;
}

.UI-N_L_AND_N {
    color: var(--TEXT_COLOR);
    font-size: 20px;
    width: 100%;
    max-width: 850px;
    display: flex;
    align-items: center;
}
.UI-N_L_AND_N .UI-Logo {
    margin-right: 15px;
}

.Content {
    width: 100%;
    max-width: 1080px;
    height: 100%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.Content-Center {
    align-items: center;
}

/* Загрузка */

.UI-Loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.UI-Loading .UI-Loader_1 {
    --uil1-size: 70px;
    --uil1-color: var(--TEXT_COLOR);
}

/* Меню действий */

.UI-ContextMenu {
    background: var(--AIR_COLOR);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    border-radius: 10px;
    width: 150px;
    padding: 5px;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    display: none;
    gap: 5px;
    flex-direction: column;
    align-items: stretch;
    box-shadow: var(--AIR_SHADOW);
}
.UI-ContextMenu button {
    background: rgb(255 255 255 / 0%);
    font-size: 1em;
    width: 100%;
    padding: 5px 15px;
    text-align: left;
    transition: .2s;
}
.UI-ContextMenu button:hover {
    background: var(--AIR_BLOCK_COLOR);
}

/* Меню */

.UI-NM_Content {
    width: auto;
    height: 100vh;
    padding: 0px 0px 40px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 20;
    transform: translateY(200%);
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
}

/* Меню - Уведомления */

.UI-NCounter {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    border-radius: 100px;
    font-size: 0.6em;
    min-width: 15px;
    height: 15px;
    padding: 2px;
    position: absolute;
    z-index: 3;
    user-select: none;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Notifications {
    border-radius: var(--BR_BASE);
    width: 100%;
    max-width: 500px;
    height: 100%;
    margin: 72px auto 7px auto;
    overflow: scroll;
    user-select: none;
}
.Notifications-Scroll {
    height: fit-content;
    margin: 8px 0px;
}
.Notifications-New {
    margin-bottom: 7px;
}
.Notifications-Notification, .Notifications-NewNotification {
    background: var(--AIR_COLOR);
    border-radius: 12px;
    width: 100%;
    padding: 7px 10px;
    margin-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: var(--AIR_SHADOW);
}
.Notifications-NewNotification  {
    background: rgb(255 255 255);
}
.Notifications-Notification .AvatarContainer {
    width: fit-content;
    margin-right: 10px;
    position: relative;
}
.Notifications-Notification .AvatarContainer svg {
    fill: var(--ACCENT_COLOR);
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    filter: drop-shadow(0px 0px 4px rgb(0 0 0 / 33%));
}
.Notifications-Notification .Avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    font-size: 20px;
    margin: 0px 5px 5px 0px;
}
.Notifications-Notification .NotificationContent {
    text-align: left;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Notifications-Notification .Title {
    font-size: 1em;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}
.Notifications-Notification .Text {
    color: var(--D_OR_P_COLOR);
    font-size: 0.889em;
    text-wrap: balance;
    word-wrap: break-word;
}
.Notifications-Notification .Date {
    color: var(--D_OR_P_COLOR);
    font-size: 0.8em;
    position: absolute;
    top: 8px;
    right: 15px;
}

/* Боковая навигация */

.UI-L_NAV {
    width: 100%;
    max-width: 200px;
}
.UI-L_NAV button {
    background: var(--BLOCK_COLOR_LITE);
    color: var(--BLOCK_COLOR_LITE_TEXT);
    font-size: var(--FS_TEXT);
    width: 100%;
    height: 35px;
    border-radius: var(--BR_BASE);
    margin-bottom: 5px;
    padding: 5px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: .2s;

    .GoldText {
        background-image: var(--GOLD_GRADIENT);
        -webkit-background-clip: text;
        color: transparent;
        display: flex;
        align-items: center;
    }
    .GoldText .icon-Nav_Sub::before {
        background-image: var(--GOLD_GRADIENT);
        -webkit-background-clip: text;
        color: transparent;
    }
}
.UI-L_NAV .UI-NCounter {
    right: 10px;
    opacity: 1;
    visibility: visible;
}
.UI-L_NAV .UI-LN_ICON {
    margin-right: 7px;
    width: 20px;
    height: 20px;
    position: relative;
}
.UI-L_NAV .UI-LN_ICON svg {
    fill: var(--BLOCK_COLOR_LITE_TEXT);
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.UI-L_NAV .ActiveButton {
    background: var(--BLOCK_COLOR);
    color: var(--TEXT_COLOR);
    box-shadow: var(--BLOCK_SHADOW);
}
.UI-L_NAV .ActiveButton .UI-LN_ICON svg {
    fill: var(--TEXT_COLOR);
}

.UI-PAGE_BODY {
    flex: 1;
    padding: 0px 10px;
    overflow: hidden;
    position: relative;
    display: flex;
}
.UI-ScrollView {
    width: 100%;
    height: 100%;
    padding: 0px 2px;
    overflow: scroll;
}
.UI-PB_PRELOAD {
    background: linear-gradient(114deg, rgb(254 254 254), rgb(254 254 254));
    z-index: 5;
    opacity: 1;
    margin: 80px 10px 0px 10px;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}
.UI-PB_PRELOAD::before {
    background: linear-gradient(45deg, rgb(254 254 254), rgb(240 240 240));
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: LOAD_PAGE 2s infinite linear;
}

.UI-ErrorMessage {
    background: var(--BLOCK_COLOR_LITE);
    color: var(--BLOCK_COLOR_LITE_TEXT);
    border-radius: var(--BR_BASE);
    width: fit-content;
    text-align: center;
    padding: 10px;
    margin: 70px auto;
}

.UI-UniversalPanel {
    background: var(--AIR_COLOR);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    font-size: var(--FS_TEXT);
    min-width: 250px;
    max-width: 400px;
    border-radius: 15px;
    margin: 3px 5px;
    padding: 10px 15px;
    position: absolute;
    z-index: 4;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    gap: var(--PUSTOTA_WIDTH);
    display: flex;
    flex-direction: column;
    transition: .2s;
}
.UI-UniversalPanel .Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.UI-UniversalPanel .UI-Switch,
.UI-UniversalPanel .Close {
    margin-left: 5px;
    position: relative;
}
.UI-UniversalPanel .Close {
    background: var(--BTN_CLOSE_COLOR);
    width: 30px;
    height: 30px;
    position: relative;
    flex-shrink: 0;
}

.UI-C_L, .UI-C_R {
    overflow: hidden;
}
.UI-C_L {
    width: 100%;
    max-width: 500px;
    margin-right: 5px;
}
.UI-C_R {
    width: 100%;
    max-width: 350px;
    margin-left: 3px;
}

/* Интерфейс - Раздел */

.UI-PartitionName {
    color: rgb(156 153 167);
    font-size: 0.78em;
    margin: 10px 0px 0px 0px;
    padding: 0px 0px 4px 14px;
}

.UI-PartitionBody {
    background: var(--AIR_COLOR);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px 10px 0px 0px;
    height: 85vh;
    padding: 10px;
    margin: 0px 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow: scroll;
    user-select: none;
    visibility: hidden;
    transform: translateY(100%);
    box-shadow: 0px -10px 10px -10px rgb(50 49 59 / 20%);
}
.UI-PB_TopBar {
    width: 100%;
    height: 30px;
    position: relative;
}
.UI-PB_BackButton {
    background: #fff0;
    color: var(--TEXT_COLOR);
    fill: var(--TEXT_COLOR);
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.UI-PB_BackButton svg {
    width: 40px;
    height: 30px;
    margin-left: -15px;
}
.UI-PB_Title {
    color: var(--TEXT_COLOR);
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.UI-PB_Content {
    margin: 20px 5px 30px 5px;
}

.UI-PB_ImagesGrup {
    position: relative;
}

.UI-PB_LinkImage {
    filter: drop-shadow(1px 2px 4px rgb(190 188 198));
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 6px;
    right: 0;
    left: 50px;
    margin: auto;
}
.UI-PB_LinkImage img {
    width: 100%;
    height: 100%;
}
.UI-PB_LinkImage svg {
    fill: var(--TEXT_COLOR);
    width: 100%;
    height: 100%;
}

.UI-PB_Image {
    width: 100px;
    margin: 50px auto 20px auto;
    display: block;
}

.UI-PB_ImageSVG {
    margin: 50px auto 10px;
    display: flex;
    justify-content: center;
}
.UI-PB_ImageSVG svg {
    width: 80px;
    height: 80px;
}
.UI-PB_ImageSVG .true {
    fill: var(--ACCENT_COLOR);
}
.UI-PB_ImageSVG .false {
    fill: var(--D_OR_P_COLOR);
}

.UI-PB_Content input[type="text"] {
    background: var(--AIR_BLOCK_COLOR);
    width: 100%;
    padding: 10px;
    margin-bottom: 10px
}
.UI-PB_Content input:hover, 
.UI-PB_Content input:focus {
    outline: 2px solid var(--AIR_BLOCK_COLOR);
}

.UI-PB_TitleText {
    width: 80%;
    font-size: 1.15em;
    margin: auto;
    text-align: center;
}

.UI-PB_InputText {
    color: rgb(163 160 172);
    text-align: center;
    font-size: 0.82em;
    margin-bottom: 10px;
    text-wrap: balance;
}

.UI-PB_NextButton {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    width: 100%;
    padding: 8px;
    margin-top: 15px;
    text-align: center;
}

.UI-PB_Button {
    background: var(--AIR_BLOCK_COLOR);
    color: var(--BTN_TEXT_COLOR);
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    text-align: center;
}

.UI-PB_Column {
    margin-top: 25px;
    border-radius: 10px;
    gap: 2px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.UI-PB_C_Element {
    background: var(--AIR_BLOCK_COLOR);
    padding: 5px 15px;
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.UI-PB_C_Element svg {
    width: 25px;
    height: 25px;
}
.UI-PB_C_Element .true {
    fill: var(--ACCENT_COLOR);
}
.UI-PB_C_Element .false {
    fill: var(--D_OR_P_COLOR);
}

/* Интерфейс - Базовые элементы */

.UI-Block {
    background: var(--BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    margin-bottom: 7px;
    padding: 10px;
    box-shadow: var(--BLOCK_SHADOW);
}
.UI-Block .BigText {
    margin: 10px 5px 10px 5px;
    font-size: 0.9em;
}
.UI-Block .BigText p {
    margin: 0px 0px 5px;
}

.UI-Title {
    color: var(--TITLE_COLOR);
    font-family: var(--FF_TITLE);
    font-size: var(--FS_TITLE);
    width: fit-content;
    margin: 8px 0px 12px 5px;
}

.UI-B_CONTENT {
    color: var(--TEXT_COLOR);
    font-size: 0.9em;
    line-height: 1.3em;
    margin: 5px;
}

.UI-LM_BTN {
    background: var(--BLOCK_COLOR);
    color: var(--TEXT_COLOR);
    border-radius: 100px;
    padding: 10px 20px;
    margin: auto;
    display: block;
}

.UI-B_FIRST {
    margin-top: var(--C_TOP_MARGIN);
}

.UI-PRELOAD {
    background: rgb(197 193 209);
    border-radius: 100px;
    position: relative;
    overflow: hidden;
}
.UI-PRELOAD::after {
    background: rgb(236 235 240);
    filter: blur(10px);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: TEXT_PRELOAD-ANIM 1s infinite ease-out;
}

.UI-ButtonLoad {
    background: rgb(255 255 255 / 80%);
    filter: blur(30px);
    opacity: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: TEXT_PRELOAD-ANIM 1s infinite ease-out;
}

.UI-EmailInfo {
    color: var(--TEXT_COLOR_LITE);
    font-size: 0.8em;
    position: sticky;
    z-index: 1;
    margin-top: 10px;
    margin-left: 10px;
}

.UI-UserIcons {
    width: 100%;
    height: 100%;
    margin: 0.4px 0px 0px 1px;
    display: flex;
    align-items: center;
}
.UI-UserIcons img,
.UI-UserIcons svg {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
.UI-UserIcons svg {
    fill: var(--ACCENT_COLOR);
}

/* Интерфейс - Ошибка */

.UI-Window_BG {
    background: rgb(23 23 23 / 50%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 20;
}

.UI-Window {
    background: var(--AIR_COLOR);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    border-radius: 13px;
    height: fit-content;
    max-height: 500px;
    width: 270px;
    padding: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 21;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.UI-Window input {
    background: var(--AIR_BLOCK_COLOR);
    width: 100%;
    height: 35px;
    padding: 0px 10px;
    margin-top: 10px;
}
.UI-Window input:hover,
.UI-Window input:focus {
    outline: 2px solid var(--AIR_BLOCK_COLOR);
}

.UI-Window_content {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    text-align: center;
}

.UI-Window_title {
    font-family: var(--FF_TITLE);
    font-size: var(--FS_TITLE);
}

.UI-Window_text {
    color: var(--TEXT_COLOR);
    font-size: var(--FS_TEXT);
    margin-top: 15px;
    text-wrap: balance;
}

.UI-Window_BTNS {
    width: 96%;
    height: 30px;
    margin: 13px 5px 5px 5px;
    display: flex;
}

.UI-Window_BW {
    width: 15px;
}

.UI-Window_button {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    width: 100%;
    height: 100%;
    border-radius: var(--BR_BASE);
    text-align: center;
    transition: 0.1s;
    outline: solid 2px rgb(150 116 202 / 0%);
    outline-offset: 4px;
}
.UI-Window_button:hover {
    outline: solid 2px rgb(150 116 202 / 50%);
    outline-offset: 2px;
}

.UI-Window_BTN_NOACT {
    background: var(--AIR_BLOCK_COLOR);
    color: var(--TEXT_COLOR);
}
.UI-Window_BTN_NOACT:hover {
    outline: solid 2px var(--AIR_BLOCK_COLOR);
}

.UI-E_IMG {
    filter: contrast(0.2);
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Интерфейс - Окно действий */

.UI-ActionWindow {
    background: var(--AIR_COLOR);
    border-radius: var(--BR_BASE);
    width: 100%;
    max-width: 850px;
    height: fit-content;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    filter: blur(1px);
}
.UI-ActionWindow .TopBar {
    padding: 10px 15px 0px 15px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.UI-ActionWindow .TopBar .Title {
    font-size: var(--FS_TITLE);
    font-family: var(--FF_TITLE);
}
.UI-ActionWindow .TopBar button {
    background: rgb(255 255 255 / 0%);
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.UI-ActionWindow .TopBar button svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 25px;
    height: 25px;
}

.UI-AW_Content {
    padding: 10px;
    user-select: none;
}

/* Интерфейс - Ползунок */

.UI-Range {
    background: var(--BLOCK_BLOCK_COLOR);
    width: 100%;
    height: 5px;
    border-radius: 5px;
    margin: 10px;
}

.UI-Range::-webkit-slider-thumb {
    background: rgb(195 184 226);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: 0px 1px 2px 1px rgb(32 29 45 / 15%);
}

/* Интерфейс - Кнопка переклюатель */

.UI-Switch {
    background: var(--BTN_BACKGROUND);
    border: 1px solid rgb(89 93 105 / 10%);
    border-radius: 100px;
    padding: 4px;
    width: 55px;
    height: 25px;
    min-width: 55px;
    cursor: pointer;
    position: relative;
    transition: .300ms;
    overflow: hidden;
    user-select: none;
    display: inline-flex;
    align-items: center;
}
.UI-Switch::after {
    content: "";
    background: #FFF;
    border-radius: 100px;
    height: 20px;
    width: 20px;
    margin: auto;
    transition-duration: 300ms;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateX(-14px);
    -webkit-box-shadow: 0px 0px 10px 1px rgb(122 122 122 / 33%);
    -moz-box-shadow: 0px 0px 10px 1px rgb(122 122 122 / 33%);
    box-shadow: 0px 0px 10px 1px rgb(122 122 122 / 33%);
}

.UI-Switch-On {
    background: var(--ACCENT_COLOR);
    border: 0px solid rgba(255, 255, 255, 0);
}
.UI-Switch-On::after {
    transform: translateX(14px);
}

/* Интерфейс - Кнопки */

.UI-Buttons {
    border-radius: var(--BR_BASE);
    margin-bottom: 7px;
    overflow: hidden;
    gap: 1px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--BLOCK_SHADOW);
}
.UI-Buttons button {
    background: var(--BLOCK_COLOR);
    border-radius: 0;
    text-align: left;
    padding: 10px 15px;
}

/* Интерфейс - Вкладки */

.UI-Tabs {
    background: var(--BTN_BACKGROUND);
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 37px;
    padding: 5px;
    margin-bottom: 7px;
    gap: var(--PUSTOTA_WIDTH);
    display: inline-flex;
    align-items: center;
}
.UI-Tabs .Tab {
    background: var(--BTN_BACKGROUND);
    color: rgb(104 101 112 / 70%);
    border-radius: 7px;
    width: 100%;
    height: 100%;
    position: relative;
    transition: .2s;
}
.UI-Tabs .ActiveTab {
    background: rgb(255 255 255);
    color: var(--BTN_TEXT_COLOR);
    box-shadow: 0px 0px 3px rgb(219 218 225);
}

/* Интерфейс - Кнопки действий */

.UI-GovernButton {
    fill: var(--BTN_TEXT_COLOR);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.UI-GovernButton svg {
    width: 13px;
    height: 13px;
}

.UI-GovernButtons {
    background: var(--AIR_COLOR);
    -webkit-backdrop-filter: saturate(600%) blur(10px);
    backdrop-filter: saturate(600%) blur(10px);
    width: fit-content;
    border-radius: var(--BR_BASE);
    padding: 5px;
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
}
.UI-GovernButtons .Container {
    border-radius: 8px;
    overflow: hidden;
    gap: var(--PUSTOTA_WIDTH);
    display: flex;
    flex-direction: column;
}
.UI-GovernButtons button {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: 0px;
    text-shadow: 0px 0px 5px rgb(231 231 231 / 90%);
    width: 100%;
    text-wrap: nowrap;
    padding: 5px 5px 5px 25px;
    position: relative;
    display: flex;
}
.UI-GovernButtons button svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 15px;
    height: 15px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
}

/* Интерфейс - Пользователи */

.UI-USR_ICONS {
    width: fit-content;
    height: 90%;
    margin-left: 2px;
    display: flex;
    align-items: center;
}

.UI-USR_ICON {
    height: 90%;
    max-height: 90%;
    min-height: 90%;
    margin-left: 2px;
    display: flex;
}

.UI-USR_ICON svg {
    fill: var(--TITLE_COLOR);
    width: 100%;
    height: 100%;
}

.UI-USR_ICON img {
    width: 100%;
    height: 100%;
}

/* Интерфейс - Реклама */

.UI-AD_N1-B {
    background: #382b4f;
    width: 350px;
    height: 180px;
    min-height: 180px;
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    position: relative;
    box-shadow: var(--AIR_SHADOW);
    -moz-box-shadow: var(--AIR_SHADOW);
    -webkit-box-shadow: var(--AIR_SHADOW);
}

.UI-AD_N2-B {
    background: var(--NL_GRADIENT);
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 180px;
    overflow: hidden;
    position: relative;
    margin-bottom: 7px;
}

.UI-AD_C_TOP {
    width: 100%;
    position: absolute;
    top: 0;
}

.UI-AD-T {
    color: #ffffffe3;
    font-size: 1.4em;
    text-shadow: #474747 1px 0 10px;
    margin: 10px;
    position: absolute;
    top: 40px;
}

.UI-AD_C_BOTTOM {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.UI-AD_TITLE {
    background: var(--AIR_COLOR);
    backdrop-filter: saturate(200%) blur(10px);
    -webkit-backdrop-filter: saturate(200%) blur(10px);
    color: var(--TITLE_COLOR);
    width: fit-content;
    border-radius: 4px;
    padding: 2px 5px;
    margin: 7px;
}

.UI-AD_BTN {
    background: var(--AIR_COLOR);
    backdrop-filter: saturate(200%) blur(10px);
    -webkit-backdrop-filter: saturate(200%) blur(10px);
    color: var(--TITLE_COLOR);
    width: 100%;
    margin: 7px;
    padding: 5px 0;
    text-align: center;
    border-radius: 8px;
    outline: 2px solid rgb(178 171 206 / 0%);
    outline-offset: 4px;
    transition: .1s;
}
.UI-AD_BTN:hover {
    outline: 2px solid rgb(255 255 255 / 50%);
    outline-offset: 2px;
}

.UI-AD_BTN:active {
    filter: brightness(0.8);
}

.UI-AD_IMG {
    width: 100%;
}

/* Интерфейс - Подключение */

.UI-ConnectBlock {
    max-width: 500px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.UI-ConnectBlock .UI-Title {
    width: 50%;
    margin: 10px auto;
    text-wrap: balance;
}
.UI-ConnectBlock .ConnectLogo {
    width: 90px;
    height: 90px;
    position: relative;
    margin: 10px 0px;
}
.UI-ConnectBlock .ConnectLogo .AppLogo {
    width: 85%;
    height: 85%;
    border-radius: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.UI-ConnectBlock .ConnectLogo .Avatar {
    width: 53%;
    height: 53%;
    position: absolute;
    right: 0;
    top: 0;
    outline: 4px solid var(--BLOCK_COLOR);
    outline-offset: 0px;
}
.UI-ConnectBlock .Info {
    font-size: 0.95em;
    margin-top: 10px;
}
.UI-ConnectBlock .Buttons {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.UI-ConnectBlock .Buttons button,
.UI-ConnectBlock .Buttons active_button {
    width: 100%;
    height: 100%;
}

/* Регистрация и вход */

.Auth-Body {
    width: 900px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.Auth-Body .Left, .Right {
    height: 100%;
    min-height: 100%;
    position: relative;
}
.Auth-Body .Left {
    width: 45%;
    min-width: 45%;
}
.Auth-Body .Right {
    width: 55%;
    min-width: 55%;
}

.Auth-Body .LogoAndTitle {
    width: fit-content;
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Auth-Body .LogoAndTitle svg {
    width: 100px;
    margin-bottom: 20px;
}
.Auth-Body .LogoAndTitle .Title {
    text-align: center;
    width: 70%;
    font-size: 1.2em;
    margin-bottom: 30px;
}
.Auth-Body .LogoAndTitle .Title span {
    font-family: var(--FF_TITLE);
}

.Auth-Body .Wathermark {
    color: rgb(139 135 154 / 50%);
    font-size: 0.8em;
    position: absolute;
    bottom: 0;
    margin: 5px 10px;
}

.Auth-Body .LastUsers {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
}
.Auth-Body .LastUsers .User {
    background: rgb(255 255 255 / 0%);
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}
.Auth-Body .LastUsers .Avatar {
    width: 40px;
    height: 40px;
}
.Auth-Body .LastUsers .Name {
    color: rgb(150 150 150);
    font-size: 0.8em;
    width: 50px;
    margin-top: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Auth-Body .LastUsers .Name .UI-PRELOAD {
    width: 40px;
    height: 15px;
    margin: auto;
}

.Auth-Body .Login, .Reg, .Verify, .Auth-Container {
    padding: 15px;
    width: 70%;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    user-select: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Auth-Body .Login {
    z-index: 2;
    opacity: 1;
    visibility: visible;
}

.Auth-Body .Reg, .Verify, .Auth-Container {
    z-index: 1;
    opacity: 0;
    visibility: hidden;
}

.REG_MAIL {
    display: none;
}

.Form_Container-Text {
    color: var(--TITLE_COLOR);
    font-family: var(--FF_TITLE);
    font-size: 20px;
    margin-bottom: 20px;
}

.Authorization-Form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.Authorization-Form input {
    padding: 10px;
    margin-bottom: 10px;
}

.Authorization-BTN_1 {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    width: 100%;
    font-size: var(--FS_TEXT);
    margin-top: 10px;
    border-radius: 14px;
    padding: 8px;
    border: 2px solid var(--ACCENT_COLOR);
}

.Authorization-BTN_2 {
    background: var(--BLOCK_COLOR);
    color: var(--ACCENT_COLOR);
    width: 100%;
    font-size: var(--FS_TEXT);
    margin-top: 10px;
    border-radius: 14px;
    padding: 5px;
}

.Authorization-Accept_R {
    color: rgb(139 135 154 / 70%);
    font-size: 0.9em;
    margin: 5px 0px 13px 0px;
    display: flex;
    align-items: center;
}

.Authorization-Accept_R_BTN {
    background: var(--ACCENT_COLOR);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

/* Статус бар - Поиск */

.Search-Result {
    background: var(--AIR_COLOR);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    border-radius: var(--BR_BASE);
    width: 100%;
    max-width: 500px;
    height: 250px;
    margin: var(--C_TOP_MARGIN) auto auto auto;
    padding: 38px 0px 0px 0px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 30;
    transform: translateY(-150%);
    opacity: 0;
    visibility: hidden;
    display: flex;
    box-shadow: var(--AIR_SHADOW);
    -moz-box-shadow: var(--AIR_SHADOW);
    -webkit-box-shadow: var(--AIR_SHADOW);
}
.Search-Result .Category {
    margin: 8px 10px auto 10px;
    font-size: 1.1em;
    position: absolute;
    top: 0;
    z-index: 1;
    flex: 1;
}
.Search-Result .Category button {
    background-color: rgb(170 170 170 / 0%);
    color: rgb(112 111 124 / 70%);
    border-radius: 6px;
    padding: 3px 10px;
    transition: background-color 0.5s;
}
.Search-Result .Category .ActiveButton {
    background-color: var(--AIR_BLOCK_COLOR);
    color: var(--BTN_TEXT_COLOR);
}
.Search-Result .UI-ScrollView {
    border-radius: var(--BR_BASE);
    padding: 0;
    margin: 0px 10px auto 10px;
}
.Search-R_Elements {
    gap: var(--PUSTOTA_WIDTH);
    display: flex;
    flex-direction: column;
}
.Search-R_Element {
    background: var(--AIR_BLOCK_COLOR);
    color: var(--TEXT_COLOR);
    border-radius: 12px;
    padding: 7px 10px;
    user-select: none;
    cursor: pointer;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Search-R_Element .Avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    font-size: 20px;
    margin-right: 8px;
}
.Search-R_Element .Cover {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    font-size: 20px;
    margin-right: 8px;
}
.Search-R_Element .Music-NoneCover {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 8px;
}
.Search-R_Element .Name {
    font-size: var(--FS_NAME);
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.Search-R_Element .Posts {
    color: var(--D_OR_P_COLOR);
    font-size: var(--FS_D_OR_P);
}
.Search-R_Element:active {
    filter: brightness(0.8);
}

.Search-Error {
    color: var(--TEXT_COLOR);
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 50%;
}

.UI-NavPanel {
    background: var(--AIR_COLOR);
    border-radius: var(--BR_BASE);
    backdrop-filter: var(--AIR_BLUR);
    padding: 8px;
    position: absolute;
    right: 0;
    top: 65px;
    z-index: 1;
    transform: rotate3d(10, -10, 1, 45deg)translate(25px, -100px)scale(0.3);
    opacity: 0;
    visibility: hidden;
    gap: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--AIR_SHADOW);
}
.UI-NavPanel button {
    background: var(--AIR_BLOCK_COLOR);
    color: var(--TEXT_COLOR);
    font-size: var(--FS_TITLE);
    border-radius: var(--BR_BASE);
    text-wrap: nowrap;
    padding: 10px;
}

/* Главная страница */

.HomePage {
    .UI-C_R {
        width: 100%;
        max-width: 350px;
    }
}
.HomePage .UI-Tabs {
    background: var(--BLOCK_COLOR);
    box-shadow: var(--BLOCK_SHADOW);
}
.HomePage .UI-Tabs .Tab {
    background: var(--BLOCK_COLOR);
}
.HomePage .UI-Tabs .ActiveTab {
    background: var(--BTN_BACKGROUND);
    box-shadow: 0px 0px 0px rgb(255 255 255 / 0%);
}
.HomePage .UI-C_L .Posts {
    margin-bottom: 20px;
}
.HomePage .Posts .UI-Loading .UI-Loader_1 {
    --uil1-size: 30px;
}

/* Главная страница - Предуприждение */

.HomePage-Warning .Buttons {
    margin-top: 10px;
    display: flex;
}
.HomePage-Warning button {
    width: 100%;
    padding: 5px
}
.HomePage-Warning .NextButton {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    transition: 0.1s;
    outline: solid 2px rgb(150 116 202 / 0%);
    outline-offset: 4px;
}
.HomePage-Warning .NextButton:hover {
    outline: solid 2px rgb(150 116 202 / 50%);
    outline-offset: 2px;
}

.HomePage-Eballss .Title {
    color: var(--BLOCK_COLOR_LITE_TEXT);
    text-align: center;
    font-size: 0.7em;
    margin-bottom: 5px;
}
.HomePage-Eballss .AAAAAAA_DA_KTO_TAKIE_ETI_VASHI_EBALLS {
    display: flex;
    align-items: center;
    justify-content: center;
}
.HomePage-Eballss .Count {
    margin-left: 5px;
    font-family: var(--FF_TITLE);
    font-size: 1.2em;
}
.HomePage-Eballss eball {
    transform: scale(0.9);
}

/* Пост */

.Post-Page {
    .UI-C_L, .UI-C_R {
        width: 100%;
        max-width: 100%;
    }
}

.Post {
    width: 100%;
    padding: 10px 10px 40px;
    position: relative;
    transition: .5s;
}
.Post .TopBar {
    display: flex;
    align-items: center;
}
.Post .TopBar .Info,
.Comment .TopBar .Info {
    color: rgb(89 93 105);
    width: 100%;
    margin: 3px;
    display: flex;
    align-items: center;
}
.Post .TopBar .Info .Avatar {
    width: 40px;
    height: 40px;
    display: block;
    margin-right: 10px;
    font-size: 20px;
}
.Post .TopBar .Info .Name {
    color: var(--TEXT_COLOR);
    font-size: var(--FS_NAME);
    width: 100%;
    margin-top: 2px;
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.Post .TopBar .Info .Name .UI-PRELOAD {
    width: 100px;
    height: 13px;
    margin-bottom: 3px;
}
.Post .TopBar .Info .Date {
    color: var(--D_OR_P_COLOR);
    font-size: var(--FS_D_OR_P);
    line-height: 1.5em;
}
.Post .TopBar .Info .Date .UI-PRELOAD {
    width: 80px;
    height: 13px;
}
.Post .TopBar .GovernButton,
.Comment .TopBar .GovernButton {
    border-radius: 100px;
    width: 25px;
    height: 25px;
    padding: 0;
    margin-right: 3px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Post .TopBar .GovernButton svg,
.Comment .TopBar .GovernButton svg {
    fill: var(--INTERACTION_BTN_TEXT_COLOR);
    width: 15px;
    height: 15px;
}
.Post .Text {
    color: var(--TEXT_COLOR);
    font-size: var(--FS_TEXT);
    margin: 7px 3px 12px;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    line-height: 1.3em;
    word-wrap: break-word;
    white-space: pre-line;
    position: relative;
}
.Post .Text .ShowMore {
    position: absolute;
    top: 100%;
    height: 100%;
    width: 100%;
    box-shadow: 0px -13px 15px 20px var(--BLOCK_COLOR);
}
.Post .Text .ShowMore button {
    background: rgb(255 255 255 / 0%);
    font-size: 1.1em;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
}
.Post .Text .UI-PRELOAD {
    height: 40px;
    border-radius: var(--BR_BASE);
}
.Post .Text .UI-PRELOAD::after {
    filter: blur(40px);
}

.Post .InteractionContainer {
    border-radius: var(--BR_BASE);
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.Post .InteractionContainer::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    box-shadow: -5px 0px 10px 10px var(--BLOCK_COLOR);
}
.Post .InteractionScroll {
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
}
.Post .InteractionButtons {
    height: 100%;
    padding: 10px;
    display: flex;
    transition: .2s;
}
.Post .InteractionButton {
    background: var(--INTERACTION_BTN_COLOR);
    color: var(--INTERACTION_BTN_TEXT_COLOR);
    fill: var(--INTERACTION_BTN_TEXT_COLOR);
    border-radius: 30px;
    width: fit-content;
    min-width: fit-content;
    height: 30px;
    padding: 0px 10px;
    margin-right: 7px;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: .3s;
}
.Post .InteractionButton svg {
    width: 19.5px;
    height: 19.5px;
    margin-right: 9px;
}
.Post .InteractionButtons .InteractionButton .UI-PRELOAD {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0px;
}
.Post .InteractionButtons .InteractionButton .UI-PRELOAD::after {
    filter: blur(20px);
}
.Post .InteractionButtons .Like {
    margin-right: 2px;
    border-radius: 100px 0px 0px 100px;
}
.Post .InteractionButtons .Dislike {
    border-radius: 0px 100px 100px 0px;
}
.Post .InteractionButtons .Liked {
    background: rgb(203 169 255 / 20%);
    color: var(--ACCENT_COLOR);
    fill: var(--ACCENT_COLOR);
}
.Post .ShareImposition {
    background: var(--BLOCK_COLOR);
    color: var(--INTERACTION_BTN_TEXT_COLOR);
    width: 100%;
    height: 30px;
    padding: 10px;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 2;
    transform: translateX(-100%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Post .ShareImposition .Interaction {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.Post .ShareImposition .Interaction .Back svg {
    margin-left: -5px;
    margin-right: -1px;
}
.Post .ShareImposition .Interaction .URL {
    background: var(--INTERACTION_BTN_COLOR);
    color: var(--INTERACTION_BTN_TEXT_COLOR);
    border-radius: 100px 0px 0px 100px;
    margin-right: 2px;
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    font-size: var(--FS_TEXT);
    display: flex;
    align-items: center;
}
.Post .ShareImposition .Interaction .URL input {
    background: rgb(255 255 255 / 0%);
    color: var(--INTERACTION_BTN_TEXT_COLOR);
    width: 100%;
    border: none;
    padding: 0;
    border-radius: 0;
}
.Post .ShareImposition .Interaction .CopyURL {
    background: var(--INTERACTION_BTN_COLOR);
    border-radius: 0px 100px 100px 0px;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Post .ShareImposition .Interaction .CopyURL svg {
    fill: var(--INTERACTION_BTN_TEXT_COLOR);
    width: 18px;
    height: 18px;
}

.UI-ImageView {
    background: rgb(0 0 0 / 70%);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 40;
}
.UI-ImageView .TopBar,
.UI-ImageView .BottomBar {
    background: rgb(24 24 24 / 50%);
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.UI-ImageView .TopBar {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    top: 0;
    z-index: 1;
}
.UI-ImageView .TopBar .Metadata {
    color: #FFF;
    margin-bottom: 3px;
    text-align: center;
}
.UI-ImageView .ImageBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.UI-ImageView .BottomBar {
    border-top: 1px solid rgb(255 255 255 / 20%);
    bottom: 0;
}
.UI-ImageView .BottomBar .Controls {
    margin-bottom: 10px;
    gap: 5px;
    display: flex;
}
.UI-ImageView .BottomBar .Controls button {
    background: rgb(0 0 0 / 35%);
    color: #FFF;
    border: 1px solid rgb(255 255 255 / 20%);
    padding: 7px;
    display: flex;
    align-items: center;
}
.UI-ImageView .BottomBar .Controls button svg {
    fill: #FFF;
    width: 21px;
    height: 21px;
    margin-right: 7px;
}
.UI-ImageView .BottomBar .Images {
    width: 100%;
    overflow: scroll;
    gap: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.UI-ImageView .BottomBar .Image {
    flex-shrink: 0;
    border-radius: var(--BR_BASE);
    width: 40px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    transition: .2s;
}
.UI-ImageView .BottomBar .Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.UI-ImageView .BottomBar .Image:hover {
    transform: scale(1.1);
}
.UI-ImageView .BottomBar .Selected {
    transform: scale(1.1);
    outline-offset: 2px;
    outline: 1px solid rgb(255 255 255 / 50%);
}

/* Контент пользователей */

.UserContent-Video {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
}
.UserContent-Video video {
    border-radius: var(--BR_BASE);
    width: 100%;
    max-height: 300px;
}

.UserContent-File {
    background: var(--INTERACTION_BTN_COLOR);
    border-radius: 10px;
    width: 100%;
    height: 60px;
    margin: 10px 0px;
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
}
.UserContent-File svg {
    fill: var(--TEXT_COLOR);
    width: 35px;
    height: 35px;
    margin-left: 15px;
}
.UserContent-File .FileInfo {
    margin-left: 7px;
    max-width: 80%;
}
.UserContent-File .FileName {
    margin-bottom: 2px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.UserContent-File .FileSize {
    color: var(--INTERACTION_BTN_TEXT_COLOR);
    font-size: 0.8em;
}
.UserContent-File a {
    background: var(--INTERACTION_BTN_TEXT_COLOR);
    color: var(--INTERACTION_BTN_COLOR);
    border-radius: 100px;
    font-size: var(--FS_TEXT);
    height: fit-content;
    padding: 5px 10px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
}
.Post .Music {
    background: var(--INTERACTION_BTN_COLOR);
    color: var(--INTERACTION_BTN_COLOR);
    border-radius: var(--BR_BASE);
    padding: 10px;
    margin-bottom: 10px;
    user-select: none;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    .Error {
        color: var(--TEXT_COLOR);
        margin-left: 10px;
        font-size: 0.9em;
    }
}
.Post .Music .UI-PRELOAD {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.Post .Music .UI-PRELOAD::after {
    filter: blur(40px);
}
.Post .Music .Music-NoneCover,
.Post .Music .Cover {
    border-radius: 8px;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}
.Post .Music .Player {
    width: 100%;
    margin: 0px 10px;
}
.Post .Music .Player .Metadata {
    color: var(--INTERACTION_BTN_TEXT_COLOR);
    font-size: 0.95em;
}
.Post .Music .Player .Metadata .Title {
    color: var(--BTN_TEXT_COLOR);
}
.Post .Music .Player .Metadata .Artist {
    font-size: 0.9em;
}
.Post .Music .Player .SliderContainer {
    gap: 5px;
    display: flex;
    align-items: center;
}
.Post .Music .Player .SliderContainer .Time {
    opacity: 0.5;
    color: var(--TEXT_COLOR);
    font-size: 0.54em;
}
.Post .Music .Player .SliderContainer .Music-Slider {
    margin-top: 0px;
}
.Post .Music .Player .SliderContainer .Progress {
    background: var(--INTERACTION_BTN_TEXT_COLOR);
}
.Post .Music .Play {
    fill: var(--INTERACTION_BTN_TEXT_COLOR);
    margin: 0px 10px;
    padding: 0;
    width: 30px;
    height: 30px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Post .Music .Play svg {
    width: 70%;
    height: 70%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.Post .Music .PauseIcon {
    filter: blur(1px);
    opacity: 0;
    transform: scale(0.5);
}
.UserContent-Image {
    background: var(--NL_GRADIENT);
    border-radius: var(--BR_BASE);
    width: 100%;
    height: auto;
    min-height: 100px;
    max-height: 300px;
    margin: 10px 0px;
    overflow: hidden;
    position: relative;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.UserContent-Image .Error {
    background: var(--AIR_COLOR);
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .Emoji {
        width: 100px;
        height: 100px;
    }
}
.UserContent-Image .IMG {
    width: 100%;
    height: 100%;
    max-height: 300px;
    position: static;
    object-fit: contain;
    cursor: pointer;
    z-index: 2;
}
.UserContent-Image .BlurIMG {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}

.UserContent-Image .Blur,
.UserContent-Image .Censoring,
.UserContent-Images .Censoring {
    background: rgb(201 201 201 / 28%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
}
.UserContent-Images .Censoring,
.UserContent-Image .Censoring {
    z-index: 3;
    display: flex;
    align-items: center;
}
.UserContent-Images .Censoring .Info,
.UserContent-Image .Censoring .Info {
    margin: auto;
    text-align: center;
}
.UserContent-Images .Censoring .Info .Text,
.UserContent-Image .Censoring .Info .Text {
    color: #FFF;
    text-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}
.UserContent-Images .Censoring .Info .ShowButton,
.UserContent-Image .Censoring .Info .ShowButton {
    background: var(--AIR_COLOR);
    color: var(--TEXT_COLOR);
    border-radius: var(--BR_BASE);
    padding: 5px 16px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
}

.UserContent-Images {
    background: var(--BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 300px;
    margin: 10px 0px;
    overflow: hidden;
    position: relative;
    user-select: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--PUSTOTA_WIDTH);
}
.UserContent-Images .P1 {
    grid-row: 1 / 3;
}
.UserContent-Images .P2 {
    grid-row: 1 / 1;
}
.UserContent-Images .P2:not(:has(+ .P3)) {
    grid-row: 1 / 3;
}
.UserContent-Images .P3 {
    grid-row: 2 / 2;
}
.UserContent-Images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
.UserContent-Images .P1,
.UserContent-Images .P2,
.UserContent-Images .P3 {
    background: var(--NL_GRADIENT);
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.UserContent-Images .Blured img {
    filter: blur(2px) brightness(0.5);
    transform: scale(1.1);
}
.UserContent-Images .P3 .Count {
    color: rgb(255 255 255 / 60%);
    font-size: 3em;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Полный список изображений */

.Post-ImagesBlocks {
    border-radius: var(--BR_BASE);
    padding: 0px;
}
.Post-ImagesBlocks .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--PUSTOTA_WIDTH);
}
.Post-Images .Block {
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 25vh;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.Post-Images .Block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
.Post-Images .Block .Info {
    background: rgb(0 0 0 / 50%);
    color: #FFF;
    overflow: hidden;
    backdrop-filter: blur(4px)saturate(200%);
    -webkit-backdrop-filter: blur(4px)saturate(200%);
    border-radius: var(--BR_BASE);
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.Post-Images .Block .Info .Name {
    width: 100%;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.Post-Images .Block .Info .Size {
    font-size: 0.9em;
    opacity: 0.7;
}

/* Комментарии */

.Comment {
    position: relative;
    .TopBar {
        display: flex;
        align-items: center;

        .Avatar {
            width: 40px;
            height: 40px;
            min-width: 40px;
            margin-right: 10px;
            font-size: 18px;
        }

        .Name {
            color: var(--TEXT_COLOR);
            font-size: var(--FS_NAME);
            width: 100%;
            margin-bottom: 1px;
            white-space: nowrap;
            display: flex;
            align-items: center;
        }
        .Date {
            color: var(--D_OR_P_COLOR);
            font-size: var(--FS_D_OR_P);
        }

        .Name .UI-PRELOAD {
            width: 100px;
            height: 13px;
            margin-bottom: 3px;
        }
        .Date .UI-PRELOAD {
            width: 80px;
            height: 13px;
        }
    }
    
    .Text {
        color: var(--TEXT_COLOR);
        font-size: var(--FS_TEXT);
        margin: 7px 3px 0px;
        width: fit-content;
        max-width: 100%;
        word-wrap: break-word;
        white-space: pre-line;
    }
    .Text .UI-PRELOAD {
        height: 25px;
        border-radius: var(--BR_BASE);
    }
    .Text .UI-PRELOAD::after {
        filter: blur(40px);
    }
}
.Comment .Reply {
    background: var(--REPLY_BG);
    border-radius: 10px;
    padding: 8px;
    margin: 10px 0px;
    user-select: none;
    position: relative;
}
.Comment .Reply .Userdata {
    display: flex;
    align-items: center;
}
.Comment .Reply .Userdata .Avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.Comment .Reply .Userdata .Username {
    font-size: 0.9em;
}
.Comment .Reply .ReplyText {
    margin-top: 5px;
    font-size: 0.9em;
}
.Comment .Reply svg {
    fill: var(--REPLY_SVG_COLOR);
    width: 40px;
    position: absolute;
    top: -5px;
    right: 0;
}
.Comment .UserContent-Image,
.Comment .UserContent-File,
.Comment .UserContent-Images {
    margin-bottom: 0;
}

.Post-Add_comment {
    border-radius: 100px;
    margin-bottom: 7px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: var(--BLOCK_SHADOW);
}
.Post-Add_comment .Reply {
    background: var(--REPLY_BG);
    display: none;
    align-items: stretch;
    justify-content: center;
}
.Post-Add_comment .Reply .ReplyContent {
    width: 100%;
    font-size: 0.9em;
    padding: 5px 15px 2px;
}
.Post-Add_comment .Reply .ReplyContent .Name {
    color: var(--ACCENT_COLOR);
    display: flex;
    align-items: center;
}
.Post-Add_comment .Reply .ReplyContent .Name .UI-UserIcons {
    width: auto;
}
.Post-Add_comment .Reply .ReplyContent .Text {
    color: var(--D_OR_P_COLOR);
}
.Post-Add_comment .Reply .Close {
    background: rgb(255 255 255 / 0%);
    width: 50px;
    position: relative;
}
.Post-Add_comment .Reply .Close svg {
    width: 25px;
    height: 25px;
}
.Post-Add_comment .Input {
    display: flex;
    align-items: stretch;
    justify-content: center;
}
.Post-Add_comment button,
.Post-Add_comment label {
    width: 50px;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Post-Add_comment .Send {
    background: var(--ACCENT_COLOR);
}
.Post-Add_comment .AddFile {
    background: var(--BLOCK_COLOR);
}
.Post-Add_comment .Send svg {
    fill: var(--AIR_COLOR);
    width: 19px;
    height: 19px;
}
.Post-Add_comment .AddFile svg {
    fill: var(--TEXT_COLOR);
    width: 21px;
    height: 21px;
}
.Post-Add_comment input {
    background: var(--BLOCK_COLOR);
    border-radius: 0;
    width: 100%;
    padding: 10px 15px;
}
.Post-Add_comment input:hover, 
.Post-Add_comment input:focus {
    outline: 2px solid rgb(243 242 246 / 0%);
    outline-offset: 2px;
}
.Post-Add_comment label:active {
    filter: brightness(0.8);
}

/* Добавить пост */

.AddPost {
    position: relative;
    user-select: none;
}
.AddPost textarea {
    width: 98%;
    height: 50px;
    margin: 10px auto;
    padding: 7px;
    overflow: hidden;
    resize: none;
    display: block;
}
.AddPost .Buttons {
    margin: 14px 5px 3px 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
}
.AddPost .Buttons .Send {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    border-radius: var(--BR_BASE);
    height: 30px;
    padding: 0px 16px;
    font-family: 'SF Pro Display Medium';
    transition: 0.1s;
    outline: solid 2px rgb(150 116 202 / 0%);
    outline-offset: 4px;
}
.AddPost .Buttons .Send:hover {
    outline: solid 2px rgb(150 116 202 / 50%);
    outline-offset: 2px;
}
.AddPost .Buttons .AddFileButtons {
    display: inline-flex;
}
.AddPost .Buttons .AddFileButtons button,
.AddPost .Buttons .AddFileButtons label {
    background: var(--BTN_BACKGROUND);
    border-radius: var(--BR_BASE);
    width: 30px;
    height: 30px;
    padding: 0px 6px;
    margin-right: 4px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.AddPost .Buttons .AddFileButtons label:active {
    filter: brightness(0.8);
}
.AddPost .Buttons .AddFileButtons svg,
.AddPost .UI-UniversalPanel .Close svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 20px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.AddPost .Buttons .AddFileButtons .Close:active {
    filter: brightness(0.8);
}
.AddPost .Buttons input {
    display: none;
}

/* Профиль */

.Profile-Page {
    width: 850px;
    margin: auto;
}
.Profile-Page .UI-C_L {
    width: 80%;
}
.Profile-Page .UI-C_R {
    width: 100%;
    max-width: 100%;
}
.Profile-Page .UI-Tabs {
    box-shadow: var(--BLOCK_SHADOW);
    background: var(--BLOCK_COLOR);
}
.Profile-Page .UI-Tabs .Tab {
    background: var(--BLOCK_COLOR);
}
.Profile-Page .UI-Tabs .ActiveTab {
    background: var(--BTN_BACKGROUND);
    box-shadow: 0px 0px 0px rgb(255 255 255 / 0%);
}

.Profile-Posts .UI-Loading .UI-Loader_1 {
    --uil1-size: 30px;
}

.Profile-InfoBlock {
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}
.Profile-Cover {
    background: var(--NL_GRADIENT);
    border-radius: 5px;
    width: 100%;
    height: 150px;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}
.Profile-Cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Profile-Cover .UI-PRELOAD {
    width: 100%;
    height: 100%;
    border-radius: 0px;
}
.Profile-Cover .UI-PRELOAD::after {
    filter: blur(40px);
}
.Profile-InfoBlock .Avatar {
    border: 4px solid var(--BLOCK_COLOR);
    width: 100px;
    height: 100px;
    margin: auto;
    font-size: 50px;
    position: absolute;
    top: 98px;
    left: 0;
    right: 0;
}
.Profile-InfoBlock .Name {
    font-family: var(--FF_TITLE);
    font-size: var(--FS_TITLE);
    margin: 15px 10px 2px 10px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Profile-InfoBlock .Username {
    color: var(--TEXT_COLOR);
    font-size: var(--FS_TEXT);
    margin: 2px 10px 15px 10px;
}
.Profile-InfoBlock .UI-UserIcons img,
.Profile-InfoBlock .UI-UserIcons svg {
    width: 17px;
    height: 17px;
    margin-left: 3px;
    cursor: pointer;
}
.Profile-InfoBlock .IconInfoContainer {
    width: 100%;
    position: relative;
}
.Profile-InfoBlock .IconInfoContainer .Info {
    background: var(--AIR_COLOR);
    -webkit-backdrop-filter: saturate(600%) blur(10px);
    backdrop-filter: saturate(600%) blur(10px);
    border-radius: var(--BR_BASE);
    font-size: var(--FS_TEXT);
    padding: 10px;
    position: absolute;
    width: 200px;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
}
.Profile-InfoBlock .ButtonsContainer {
    width: fit-content;
    position: relative;
}
.Profile-InfoBlock .ButtonsContainer .Buttons {
    height: 30px;
    margin: 0px 0px 15px 0px;
    border-radius: var(--BR_BASE);
    overflow: hidden;
    position: relative;
    gap: 2px;
    display: flex;
}
.Profile-InfoBlock .ButtonsContainer .Buttons .Button, .SubButton {
    border-radius: 0px;
    height: 100%;
    padding: 0px 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Profile-InfoBlock .ButtonsContainer .Buttons .SubButton {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    letter-spacing: 0.03em;
}
.Profile-InfoBlock .ButtonsContainer .Buttons .Button svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 20px;
    height: 20px;
}
.Profile-InfoBlock .UI-GovernButtons {
    width: 100%;
    top: 35px;
    left: 0;
    right: 0;
}
.Profile-InfoBlock .Info {
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 50px;
    overflow: hidden;
    display: flex;
    gap: var(--PUSTOTA_WIDTH);
}
.Profile-InfoBlock .Info .Container {
    background: var(--BLOCK_BLOCK_COLOR);
    border-radius: 0px;
    font-size: 1em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Profile-InfoBlock .Info .Value {
    height: 20px;
    display: flex;
    align-items: center;
}
.Profile-InfoBlock .Info .Title {
    font-size: 0.9em;
    color: var(--D_OR_P_COLOR);
}
.Profile-InfoBlock .Description {
    background: var(--BLOCK_BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    margin-top: 5px;
    width: 100%;
    padding: 8px 7px;
}
.Profile-InfoBlock .Description .Title {
    color: var(--D_OR_P_COLOR);
    font-size: 0.9em;
    margin-bottom: 4px;
}
.Profile-InfoBlock .Description .Text {
    word-wrap: break-word;
    white-space: pre-line;
    user-select: text;
}
.Profile-InfoBlock .Description a {
    user-select: none;
}
.Profile-InfoBlock .Links {
    border-radius: var(--BR_BASE);
    margin-top: 5px;
    width: 100%;
    display: flex;
    overflow-x: scroll;
}
.Profile-InfoBlock .Links button {
    background: var(--BLOCK_BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    width: fit-content;
    margin-right: 5px;
    padding: 6px 13px;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
}
.Profile-InfoBlock .Links button img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
}
.Profile-InfoBlock .Links button svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 17px;
    height: 17px;
    margin-right: 5px;
}

.Profile-P_Column {
    margin: 10px 0px;
    gap: 7px;
    display: flex;
    flex-direction: column;
}
.Profile-P_Column .Element {
    margin: 0px 5px;
    font-size: var(--FS_TEXT);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Profile-Subs {
    padding: 0;
    border-radius: 10px;
    gap: var(--PUSTOTA_WIDTH);
    display: flex;
    flex-direction: column;
}
.Profile-Subs .Search-R_Element {
    width: 100%;
    font-size: 1em;
}
.Profile-Subs .UI-ErrorMessage {
    background: var(--AIR_BLOCK_COLOR);
    margin: auto;
}

/* Чат */

.Messanger {
    width: 100%;
    height: auto;
    margin: var(--C_TOP_MARGIN) 0px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Chats {
    background: var(--BLOCK_COLOR);
    border-radius: 8px;
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.Chats-Title {
    background: var(--AIR_COLOR);
    backdrop-filter: blur(15px);
    border-bottom: 1px rgb(238 238 238) solid;
    width: 100%;
    height: 55px;
    white-space: nowrap;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
}
.Chats-Title .Title,
.Chats-Title .Connection {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    right: 0;
    margin: auto;
    height: fit-content;
}
.Chats-Title .Title {
    color: var(--TEXT_COLOR);
    font-family: var(--FF_TITLE);
    font-size: var(--FS_TITLE);
    opacity: 0;
    visibility: hidden;
    filter: blur(0px);
}
.Chats-Title .Connection {
    gap: 10px;
    display: flex;
    align-items: center;
}
.Chats-Title .Connection .UI-Loader_1 {
    --uil1-size: 30px;
    --uil1-color: var(--TEXT_COLOR);
}

.Chats-List_scroll {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-x: scroll;
}

.Chats-List {
    width: 95%;
    margin-top: 65px
}

.Chats-User {
    background: var(--BLOCK_BLOCK_COLOR_LITE);
    border-radius: var(--BR_BASE);
    width: 100%;
    min-height: 50px;
    margin: 5px 8px;
    padding: 7px 10px;
    text-align: left;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    .Avatar {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        font-size: 20px;
    }
}
.Chats-User .UI-NCounter {
    padding: 0px 5px;
    margin: 10px 15px;
    z-index: 0;
    top: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
}
.Chats-User_New {
    background: rgb(198 180 226 / 30%);
}
.SavesAvatar {
    background: var(--ACCENT_COLOR);
    display: flex;
    align-items: center;
    justify-content: center;
}
.SavesAvatar svg {
    fill: #FFF;
    width: 25px;
    height: 25px;
}

.Chats-NandLM {
    margin: 5px 15px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Chats-Name {
    color: var(--TEXT_COLOR);;
    font-family: var(--FF_TITLE);
    margin-bottom: 3px;
}
.Chats-LastMessage {
    color: var(--D_OR_P_COLOR);
    font-size: 0.87em;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Chats-Name .UI-PRELOAD {
    width: 100px;
    height: 13px;
    margin-bottom: 3px;
}
.Chats-LastMessage .UI-PRELOAD {
    width: 150px;
    height: 13px;
}

.Chat {
    background: var(--BLOCK_COLOR);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
    -moz-box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
    box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
}

.Chat-Error {
    background: var(--BLOCK_COLOR);
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
    display: flex;
    justify-content: center;
    position: absolute;
}

.Chat-Error_message,
.Chat-SelectKeyWord {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.Chat-Error_message {
    background: var(--BLOCK_BLOCK_COLOR);
    color: var(--TEXT_COLOR);
    font-size: var(--FS_TEXT);
    max-width: 85%;
    width: fit-content;
    padding: 10px;
    border-radius: var(--BR_BASE);
}

.Chat-SelectKeyWord {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Chat-SelectKeyWord .Title {
    font-size: 0.9em;
    text-align: center;
    margin-bottom: 15px;
}
.Chat-SelectKeyWord textarea {
    width: 100%;
    padding: 10px;
    resize: none;
}
.Chat-SelectKeyWord .Buttons {
    width: 100%;
    margin-top: 10px;
    gap: var(--PUSTOTA_WIDTH);
    display: flex;
    flex-direction: row;
}
.Chat-SelectKeyWord button,
.Chat-SelectKeyWord active_button {
    width: 100%;
    padding: 8px;
}

.Chat-TopBar, .Chat-TopWarning, .Chat-DownBar {
    background: var(--AIR_COLOR);
    backdrop-filter: blur(15px) saturate(200%);
    -webkit-backdrop-filter: blur(15px) saturate(200%);
    width: 100%;
    position: absolute;
    user-select: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.Chat-TopBar {
    border-bottom: 1px rgb(238 238 238) solid;
    color: var(--TEXT_COLOR);
    fill: var(--TEXT_COLOR);
    height: 55px;
    padding: 5px 20px;
    align-items: center;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .Back {
        width: 25px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Back svg {
        transform: scale(1.2);
    }
    .Avatar {
        width: 40px;
        height: 40px;
    }
}
.Chat-TB_Data {
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Chat-Name {
    margin-bottom: 1px;
    font-size: 1.1em;
}
.Chat-Name a {
    color: var(--TEXT_COLOR);
}
.Chat-Status {
    color: var(--D_OR_P_COLOR);
    font-size: 0.85em;
}

.Chat-DownBar {
    border-top: 1px rgb(238 238 238) solid;
    height: 55px;
    padding: 5px 20px;
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: space-between;
}
.Chat-DownBar input {
    width: 100%;
    height: 35px;
    border-radius: 100px;
    padding: 0px 20px;
}

.Chat-TopWarning {
    transform: translateY(-100px) scale(0.9);
    opacity: 0;
    margin-top: 55px;
    padding: 10px;
    border-bottom: 1px rgb(238 238 238) solid;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Text {
        font-size: 0.9em;
        text-align: center;
        width: 90%;
    }

    .Buttons {
        border-radius: var(--BR_BASE);
        width: 90%;
        height: 23px;
        margin-top: 10px;
        overflow: hidden;
        display: flex;

        .Apply, .Close {
            background: var(--BTN_BACKGROUND);
            border-radius: 0;
            color: var(--TEXT_COLOR);
            width: 100%;
            height: 100%;
        }

        .Close {
            background: var(--BTN_CLOSE_COLOR);
            border-radius: 0px;
            margin-right: 0px;
        }
    }
}

.Chat-NonMessages {
    background: var(--BLOCK_BLOCK_COLOR_LITE);
    color: var(--BLOCK_COLOR_LITE_TEXT);
    border-radius: 20px;
    font-size: 0.9em;
    width: 60%;
    margin: auto;
    padding: 10px;
    text-align: center;
}
.Chat-NonMessages svg {
    fill: var(--TEXT_COLOR);
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.Chat-Send_button {
    background: var(--ACCENT_COLOR);
    border-radius: 100px;
    width: 30px;
    min-width: 35px;
    height: 35px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Chat-Send_button svg {
    fill: #FFF;
    height: 19px;
}

.Chat-Messanges {
    width: 90%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.Chat-Messanges_scroll {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Chat-Messanges_list {
    padding: 60px 0px;
    min-height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.Chat-Messanges_list .UI-Loader_2 {
    --uil2-color: var(--TEXT_COLOR_LITE);
    margin: 10px auto;
}

.Chat-M_Me, .Chat-M_URS {
    font-size: 0.9em;
    position: relative;
    
    .Time {
        color: var(--TEXT_COLOR);
        font-size: 0.7em;
        position: absolute;
        right: 13px;
        bottom: 4px;
        opacity: 0.6;
        user-select: none;
    }
}

.Chat-M_Me .UI-PRELOAD, 
.Chat-M_URS .UI-PRELOAD {
    position: absolute;
    border-radius: 0px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
}
.Chat-M_Me .UI-PRELOAD::after, 
.Chat-M_URS .UI-PRELOAD::after {
    filter: blur(20px);
}

.Chat-M_Me {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    font-size: var(--FS_TEXT);
    width: fit-content;
    max-width: 300px;
    margin: 3px 10px 3px auto;
    padding: 8px 50px 8px 15px;
    border-radius: 20px 20px 1px 20px;
    word-wrap: break-word;
    white-space: pre-line;

    .Time {
        color: rgb(255 255 255);
    }
}

.Chat-M_URS {
    background: var(--BLOCK_BLOCK_COLOR);
    color: var(--TEXT_COLOR);
    font-size: var(--FS_TEXT);
    width: fit-content;
    max-width: 300px;
    padding: 8px 50px 8px 15px;
    margin: 3px auto 3px 10px;
    border-radius: 20px 20px 20px 1px;
    word-wrap: break-word;
    white-space: pre-line;
}

/* Настройки */

.Settings-Page {
    flex-direction: row-reverse;
}
.Settings-Page .UI-C_L {
    position: relative;
    width: 100%;
}
.Settings-Page .UI-C_R {
    width: 100%;
    max-width: 350px;
}
.Settings-Page .UI-Block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Settings-Page .UI-Title {
    width: 100%;
}
.Settings-Page .Profile-InfoBlock .Profile-Cover {
    height: 100px;
}
.Settings-Page .Profile-InfoBlock .Avatar {
    width: 80px;
    height: 80px;
    font-size: 40px;
    top: 105px;
}
.Settings-Page .Profile-InfoBlock .Username {
    margin: 2px 10px 0px 10px;
}

/* Настройки - Предпросмотр профиля */

.Settings-PRFL_Email {
    background: var(--BLOCK_BLOCK_COLOR);
    border-radius: 10px;
    width: fit-content;
    margin-top: 10px;
    padding: 4px 10px;
    position: relative;
    overflow: hidden;
    transition: .2s;
    user-select: all;
}

.Settings-PRFL_Email::after {
    content: '';
    background: var(--BLOCK_BLOCK_COLOR);
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    transform: translateX(0);
    box-shadow: -11px 0px 9px 10px var(--BLOCK_BLOCK_COLOR);
    transition: .2s;
}

.Settings-PRFL_Email.hover::after {
    transform: translateX(120%);
}

/* Настройки - Редактирование профиля */

.Settings-ChangeButtons {
    width: fit-content;
    border-radius: 10px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    user-select: none;
    display: flex;
    flex-direction: column;
}
.Settings-ChangeButtons input {
    display: none;
}
.Settings-ChangeButtons .Button {
    background: var(--BTN_BACKGROUND);
    color: var(--BTN_TEXT_COLOR);
    font-size: var(--FS_TEXT);
    border-radius: 0px;
    margin-bottom: 1.5px;
    padding: 5px 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.Settings-ChangeButtons .Button:active {
    filter: brightness(0.8);
}
.Settings-ChangeButtons .ButtonDL {
    background: var(--BTN_CLOSE_COLOR);
    color: var(--BTN_TEXT_COLOR);
    font-size: var(--FS_TEXT);
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 5px 10px;
}

.Settings-CP_Cover {
    background: var(--NL_GRADIENT);
    border-radius: 5px;
    width: 100%;
    height: 130px;
    position: relative;
    overflow: hidden;
}
.Settings-CP_Cover .Settings-ChangeButtons {
    border-radius: 5px 0px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
}
.Settings-CP_Cover .Settings-ChangeButtons .Button {
    background: rgb(242 242 242 / 70%);
    backdrop-filter: blur(3px);
    color: rgb(79 79 79);
    border-radius: 5px 0px 0px 0px;
}
.Settings-CP_Cover .Settings-ChangeButtons .ButtonDL {
    backdrop-filter: blur(3px);
    color: rgb(79 79 79);
}

.Settings-CP_Cover .Cover {
    width: 100%;
    height: 100%;
}
.Settings-CP_Cover .Cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Settings-CP_Avatar {
    margin: 4px 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Settings-CP_Avatar .Avatar {
    width: 70px;
    height: 70px;
    font-size: 35px;
    position: relative;
    left: 14px;
    z-index: 1;
}
.Settings-CP_Avatar .Settings-ChangeButtons {
    right: 14px;
}
.Settings-CP_Avatar .Button {
    padding: 5px 10px 5px 40px;
}
.Settings-CP_Avatar .ButtonDL {
    padding: 5px 10px 5px 40px;
}

.Settings-CP_Input_container {
    width: 100%;
    margin: 0px 0px 3px 0px;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
}

.Settings-CP_IC_Title {
    color: rgb(156 153 167);
    font-size: 0.9em;
    margin: 6px 0px 5px 6px;
}

.Settings-CP_Input_container input {
    width: 100%;
    height: 34.7px;
    padding: 0px 9px;
    position: sticky;
    z-index: 1;
}

.Settings-CP_Input_container textarea {
    width: 100%;
    height: 80px;
    padding: 7px 9px;
    position: sticky;
    z-index: 1;
    overflow: hidden;
    resize: none;
}

.Settings-Links {
    border-radius: var(--BR_BASE);
    display: flex;
    overflow-x: scroll;
}
.Settings-Links button {
    background: var(--BLOCK_BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    width: fit-content;
    margin-right: 10px;
    padding: 4px 9px;
    font-size: 0.85em;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
}
.Settings-Links button img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.Settings-Links button svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

/* Настройки - Применить изменения */

.Settings-Q_container {
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    z-index: 2;
}
.Settings-Q_container .Question {
    background: var(--AIR_COLOR);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    border-radius: var(--BR_BASE);
    width: 100%;
    padding: 10px;
    position: absolute;
    transform: translate(0) scale(0.5);
    opacity: 0.1;
    top: -40px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Settings-Q_container .Apply {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    border-radius: 10px;
    width: 100%;
    height: 30px;
    text-align: center;
    margin-right: 5px;
}
.Settings-Q_container .Back {
    background: var(--BODY_COLOR);
    color: var(--TEXT_COLOR);
    margin-left: 5px;
    border-radius: 10px;
    width: 100%;
    height: 30px;
    text-align: center;
    border: 1px solid rgb(89 93 105 / 10%);
}

/* Настройки - Выбор темы */

.Settings-Themes {
    width: 100%;
    padding: 0px 7px;
    display: flex;
    overflow: scroll;
    .Info {
        color: var(--TEXT_COLOR);
        font-size: 10px;
        width: 75px;
        margin-top: 5px;
        text-align: center;
    }
    .Selected {
        background: var(--BTN_BACKGROUND);
        border-radius: 100px;
    }
}

/* Настройки - Разделы */

.Settings-Author {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
}
.Settings-Author .Avatar {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    cursor: pointer;
}
.Settings-Author .Info {
    margin-left: 10px;
}
.Settings-Author .Name {
    font-size: 1em;
    margin-top: 3px;
    cursor: pointer;
}
.Settings-Author .JobTitle {
    color: var(--BLOCK_COLOR_LITE_TEXT);
    font-size: 0.87em;
    margin-top: 5px;
}

.Settings-GoldInfoBlock {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    padding: 10px;
    margin-bottom: 7px;
    display: flex;
}

.Settings-GoldInfoStatus {
    font-size: 1.3em;
    font-family: var(--FF_TITLE);
    text-align: center;
    margin: 40px 0px;
}

.Settings-Anim {
    color: rgb(112 111 124 / 70%);
    width: 100%;
    font-size: 0.8em;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.Settings-Anim item {
    width: 100%;
    text-align: center;
}

.Settings-PType {
    width: 100%;
    gap: 5px;
    display: flex;
}
.Settings-PType button {
    width: 100%;
    padding: 5px;
}
.Settings-PType .Active {
    background: rgb(195 184 226);
    color: rgb(27 19 48 / 80%);
}

/* Панель управления */

.Dashboard-Blocks {
    margin-bottom: 7px;
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(4, 1fr);
}

.Dashboard-Block {
    height: 150px;
    margin: 0;
    overflow: hidden;
    position: relative;
}
.Dashboard-Block svg {
    fill: rgb(220 217 233);
    width: 120px;
    height: 105px;
    position: absolute;
    right: -20px;
    bottom: -10px;
}
.Dashboard-B_Text {
    color: var(--TITLE_COLOR);
    font-family: var(--FF_TITLE);
    font-size: var(--FS_TITLE);
}
.Dashboard-B_Count {
    color: var(--TEXT_COLOR);
    font-size: 40px;
    position: absolute;
    z-index: 1;
    bottom: 10px;
}

.Dashboard-BL_CNT {
    width: 100%;
}

.Dashboard-SUB_BTN {
    background: var(--BTN_BACKGROUND);
    color: var(--TEXT_COLOR);
    width: 95%;
    height: 35px;
    margin: 5px auto;
    display: block;
    border-radius: var(--BR_BASE);
}

.Dashboard-SUB_LIST {
    width: 95%;
    height: 390px;
    margin: 10px auto;
    overflow: auto;
    border-radius: var(--BR_BASE);
}

.Dashboard-SUB_KEY {
    height: 35px;
    border-radius: var(--BR_BASE);
    padding: 10px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}

.Dashboard-SK_A {
    background: linear-gradient(90deg, var(--BTN_BACKGROUND) 60%, rgb(255 219 219) 100%);
}

.Dashboard-SK_NA {
    background: linear-gradient(90deg, var(--BTN_BACKGROUND) 60%, rgb(209, 255, 202) 100%);
}

.Dashboard-SUB_USR {
    background: var(--BTN_BACKGROUND);
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 55px;
    font-size: 1em;
    padding: 0;
    margin-bottom: 4px;
    text-align: left;
    display: flex;
    align-items: center;

    .Avatar {
        width: 40px;
        height: 40px;
        display: block;
        margin: 0px 10px 0px 15px;
        font-size: 20px;
    }

    .Name {
        color: var(--TEXT_COLOR);
        font-size: var(--FS_NAME);
        white-space: nowrap;
        max-width: 200px;
    }    

    .Posts {
        color: var(--D_OR_P_COLOR);
        font-size: var(--FS_D_OR_P);
    }
}

.Dashboard-BC {
    display: flex;
}
.Dashboard-BC .Button {
    border-radius: var(--BR_BASE);
    padding: 8px 15px;
}

.Dashboard-Search {
    display: flex;
    padding: 5px;
}
.Dashboard-Search input {
    padding: 8px 13px;
    width: 100%;
    height: 100%;
}
.Dashboard-Search button {
    background: var(--ACCENT_COLOR);
    color: var(--BLOCK_COLOR);
    width: 40px;
    font-size: 16px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Панель управления - Пользователи */

.Dashboard-Users {
    margin-bottom: 100px;
}

.Dashboard-User {
    position: relative;
    display: flex;
    align-items: center;
}
.Dashboard-User .Avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    font-size: 20px;
}
.Dashboard-User .BaseInfo {
    margin-left: 10px;
}
.Dashboard-User .Name {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Dashboard-User .Username {
    color: var(--D_OR_P_COLOR);
    font-size: var(--FS_TEXT);
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Dashboard-User .LiteInfo {
    color: var(--D_OR_P_COLOR);
    position: absolute;
    left: 220px;
    display: flex;
}
.Dashboard-User .LiteInfo .Text {
    color: var(--D_OR_P_COLOR);
    font-size: 0.9em;
    margin: 0px 0px 0px 20px;
}
.Dashboard-User .GovernButtons {
    position: absolute;
    right: 20px;
    display: flex;
}
.Dashboard-User .GovernButtons button {
    background: var(--BTN_BACKGROUND);
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
    display: flex;
}
.Dashboard-User .GovernButtons svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 20px;
    height: 20px;
}
.Dashboard-User .ChangePassword {
    background: var(--BLOCK_COLOR);
    position: absolute;
    right: 20px;
    display: flex;
    transform: translateX(120%);
}
.Dashboard-User .ChangePassword input {
    padding: 8px 13px;
    width: 200px;
}
.Dashboard-User .ChangePassword button {
    width: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Dashboard-User .ChangePassword #USER-CP_SEND {
    background: var(--ACCENT_COLOR);
    color: var(--BLOCK_COLOR);
    margin-left: 5px;
}
.Dashboard-User .ChangePassword #USER-CP_CLOSE {
    fill: var(--BTN_TEXT_COLOR);
    margin-left: 10px;
}

.Dashbaard-BottomBar {
    background: rgb(255 255 255 / 70%);
    backdrop-filter: var(--AIR_BLUR);
    border: 1px solid var(--INPUT_COLOR);
    border-radius: 100px;
    width: auto;
    height: 50px;
    user-select: none;
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
}
.Dashbaard-BottomBar .Next {
    background: rgb(255 255 255 / 0%);
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.Dashbaard-BottomBar .Next svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 30px;
    height: 30px;
    margin-right: -7px;
    transform: scaleX(-1);
}
.Dashbaard-BottomBar .Back {
    background: rgb(255 255 255 / 0%);
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.Dashbaard-BottomBar .Back svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 30px;
    height: 30px;
    margin-left: -7px;
}
.Dashbaard-BottomBar .NonActiveButton {
    opacity: 0.5;
    cursor: unset;
}
.Dashbaard-BottomBar .NonActiveButton:active {
    filter: brightness(1);
}
.Dashbaard-BottomBar .Pages {
    color: var(--D_OR_P_COLOR);
    font-size: 0.9em;
    width: fit-content;
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}

/* Информация */

.Info-A_Block {
    border: 2px solid var(--BLOCK_BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    padding: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Info-A_Block .BlockContent {
    display: flex;
    flex-direction: column;
}
.Info-A_Block .BlockContent .Title {
    font-family: var(--FF_TITLE);
    font-size: 1.2em;
    margin-bottom: 5px;
}
.Info-A_Block .BlockContent .Description{
    font-size: 0.95em;
}
.Info-A_Block .Icon {
    width: 130px;
    height: fit-content;
    flex-shrink: 0;
}

.Info-Block {
    width: 100%;
}

.Info-BL_R {
    background: var(--BLOCK_BLOCK_COLOR);
    border-radius: var(--BR_BASE);
    padding: 10px 8px;
    white-space: initial;
}

.Info-BL_R_IF {
    background: rgb(251 233 233 / 30%);
    border: 1px solid rgb(245 211 211 / 70%);
    border-radius: 11px;
    width: 100%;
    padding: 5px 10px;
    margin-top: 8px;
}

.Info-UPT_B {
    background: var(--BLOCK_BLOCK_COLOR);
    padding: 10px 8px;
    margin-bottom: 5px;
    border-radius: var(--BR_BASE);
}

.Info-UPT_B_T {
    color: var(--TEXT_COLOR);
    font-family: var(--FF_TITLE);
    font-size: 0.9em;
    margin: 5px 0px 0px 0px;
}

.Info-UPT_B_C {
    margin: 10px 0px 0px 0px;
    font-size: 0.84em;
    line-height: 18px;
    white-space: pre-line;
}

.Info-API_QT {
    color: rgb(136 133 143);
    font-size: 0.85em;
    margin: 3px 17px 1px 17px;
    user-select: none;
}

.Info-API_Query {
    background: var(--BLOCK_BLOCK_COLOR);
    border: 1px solid var(--AIR_BLOCK_COLOR);
    color: #FFF;
    width: fit-content;
    font-size: 0.9em;
    border-radius: 10px;
    margin: 0px 5px 5px 5px;
    padding: 6px 10px;
}
.Info-API_Query .URL {
    color: var(--ACCENT_COLOR);
    display: flex;
}
.Info-API_Query .URL .Varianti {
    color: #97a8b6;
}
.Info-API_Query .Headers {
    color: rgb(186 130 132);
    margin-top: 5px;
}
.Info-API_Query .Method {
    color: rgb(96 130 198);
    margin-top: 5px;
}
.Info-API_Query .Data {
    color: #8397be;
    white-space: pre-line;
    margin-top: 5px;
    margin-left: 5px;
    padding: 0;
    position: relative;
}
.Info-API_Query .Data::after {
    content: '';
    background: #6082c6;
    border-radius: 5px;
    width: 1px;
    height: 100%;
    position: absolute;
    font-size: 100%;
    top: 0;
    left: -5px;
}
.Info-API_Query .JSON {
    color: #1da67f;
    white-space: pre;
}

/* Подписка */

.GoldSub-Page {
    .UI-C_L {
        position: relative;
        
        .UI-Block {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .UI-Title {
            width: 100%;
        }
    }
    .UI-C_R {
        width: 100%;
        max-width: 350px;
    }
}
.GoldSub-Page .UI-ScrollView {
    padding-bottom: 130px;
}

.GoldSub-Info_action {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.GoldSub-C_L {
    .UI-Block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .UI-Title {
        width: 100%;
    }
    .UI-B_LAST {
        margin-bottom: 40px;
    }
}

.GoldSub-Logo {
    width: 110px;
    height: 110px;
    margin: 5px 0px 15px 0px;
    filter: drop-shadow(3px 3px 5px rgba(251 176 11 / 40%));
}

.GoldSub-Price {
    background-image: var(--GOLD_GRADIENT);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 20px;
    font-family: var(--FF_TITLE);
    margin: 8px 0px 20px 0px;
}

.GoldSub-Advantages {
    border-radius: var(--BR_BASE);
    overflow: hidden;
    gap: var(--PUSTOTA_WIDTH);
    display: flex;
    flex-direction: column;
}

.GoldSub-A_Block {
    background: var(--BTN_BACKGROUND);
    font-size: var(--FS_TEXT);
    padding: 7px 10px;
    cursor: pointer;
    user-select: none;
}
.GoldSub-A_Block:active {
    filter: brightness(0.8);
}

.GoldSub-A_B_TITLE {
    font-size: var(--FS_TITLE);
    margin-bottom: 4px;
    background-image: var(--GOLD_GRADIENT);
    -webkit-background-clip: text;
    color: transparent;
}

.GoldSub-Buttons {
    background: var(--AIR_COLOR);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 20px;
    z-index: 1;
    border-radius: var(--BR_BASE);
    box-shadow: 0px 0px 10px -5px rgb(64 64 64 / 70%);

    .Pay, .Activate {
        font-size: var(--FS_TEXT);
        border-radius: 8px;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Pay {
        background: var(--GOLD_GRADIENT);
        color: #FFF;
        margin-bottom: 8px;    
        box-shadow: 0px 0px 9px -1px rgb(253 177 102);
        -moz-box-shadow: 0px 0px 9px -1px rgb(253 177 102);
        -webkit-box-shadow: 0px 0px 9px -1px rgb(253 177 102);
    }
    .Activate {
        background: var(--AIR_BLOCK_COLOR);
        color: var(--TEXT_COLOR);
    }
}

.GoldSub-Users .UI-PRELOAD {
    border-radius: var(--BR_BASE);
    width: 100%;
    height: 60px;
    margin-bottom: 7px;
}
.GoldSub-Users .UI-PRELOAD::after {
    filter: blur(50px);
}

.GoldSub-User {
    background: linear-gradient(114deg, rgb(221 183 127 / 10%) 60%, #fff4dd);
    border-radius: var(--BR_BASE);
    padding: 10px;
    margin-bottom: 7px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: row;

    .Avatar {
        background: var(--GOLD_GRADIENT);
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        font-size: 20px;
        margin-right: 10px;
    }

    .Name {
        color: var(--TEXT_COLOR);
        font-size: var(--FS_NAME);
        white-space: nowrap;
    }

    .Posts {
        color: var(--D_OR_P_COLOR);
        font-size: var(--FS_D_OR_P);
    }

    .GoldStar {
        width: 65px;
        position: absolute;
        bottom: -15px;
        right: -12px;
    }
}
.GoldSub-User::before {
    position: absolute;
    content: '';
    inset: -4px;
    padding: 5px;
    border-radius: 14px;
    background: linear-gradient(465deg, rgb(250 179 30 / 30%), rgb(253 147 71 / 10%));
    mask: linear-gradient(fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
}

.GoldSub-VideoPrew, .GoldSub-InfoPrew {
    background: var(--AIR_COLOR);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px 10px 0px 0px;
    height: fit-content;
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transform: translateY(570px);
    opacity: 0;
    box-shadow: var(--AIR_SHADOW);

    .Info {
        margin: 10px 0px 20px 0px;
    }
    .InfoTitle {
        font-family: var(--FF_TITLE);
        margin-bottom: 4px;
        background-image: var(--GOLD_GRADIENT);
        -webkit-background-clip: text;
        color: transparent;
    }
    .InfoDec {
        font-size: 0.9em;
        margin-bottom: 25px;
    }
    .Close {
        background: var(--AIR_BLOCK_COLOR);
        color: var(--TEXT_COLOR);
        border-radius: var(--BR_BASE);
        width: 100%;
        height: 40px;
    }
}

.GoldSub-VideoPrew video {
    border-radius: var(--BR_BASE);
    width: 100%;
    overflow: hidden;
    opacity: 0;
}

.GoldSub-InfoPrew .Info {
    margin: 0px 0px 20px 0px;
}
.GoldSub-InfoPrew .InfoDec .Title {
    font-size: 0.9em;
    margin: 10px 10px 3px;
}
.GoldSub-InfoPrew .InfoDec .InfoContainer {
    border-radius: var(--BR_BASE);
    overflow: hidden;
    width: 100%;
    display: flex;
}
.GoldSub-InfoPrew .InfoDec .InfoContainer .Default {
    background: var(--AIR_BLOCK_COLOR);
    width: 100%;
    padding: 5px 10px;
}
.GoldSub-InfoPrew .InfoDec .InfoContainer .Gold {
    background: var(--GOLD_GRADIENT);
    color: #FFF;
    width: 100%;
    padding: 5px 10px;
}

/* Просмотр EPACK */

.EPACK-FileInput {
    width: 100%;
    padding: 5px;
    user-select: none;
    display: flex;
    align-items: center;
}
.EPACK-FileInput label {
    background: var(--BTN_BACKGROUND);
    color: var(--BTN_TEXT_COLOR);
    border-radius: 6px;
    padding: 7px 10px;
    cursor: pointer;
    text-align: center;
}
.EPACK-FileInput label:active {
    filter: brightness(0.8);
}
.EPACK-FileInput input[type="file"] {
    display: none;
}
.EPACK-FileInput .Text {
    margin-left: 10px;
}

.EPACK-Post {
    padding: 10px;
    .TopBar {
        .Avatar {
            width: 40px;
            height: 40px;
            display: block;
            margin: 3px 10px 3px 3px;
            font-size: 20px;
        }
    }
    .Interaction {
        width: 100%;
        height: 30px;
        padding: 0px 10px;
        display: flex;
        
        .InteractionCount {
            background: rgb(255 255 255 / 0%);
            color: var(--TEXT_COLOR);
            fill: var(--TEXT_COLOR);
            width: fit-content;
            padding: 0;
            margin-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .InteractionCount svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
}

/* Музыка */

/* Музыка - Элементы */

.Music-NoneCover {
    background: rgb(212 205 223);
    border-radius: 10px;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Music-NoneCover svg {
    fill: var(--ACCENT_COLOR);
    width: 70%;
    height: 70%;
}

.Music-Slider {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: 2px;
    width: 100%;
    height: 5px;
    margin-top: 7px;
    cursor: pointer;
    overflow: hidden;
    transition: .2s;
}
.Music-Slider .Progress {
    background: var(--BLOCK_COLOR_LITE_TEXT);
    width: 0%;
    height: 100%;
    transition: .2s;
}
.Music-Slider .UI-PRELOAD {
    background: rgb(255 255 255 / 0%);
    height: 100%;
    margin: 0;
    border-radius: 0;
}
.Music-Slider .UI-PRELOAD::after {
    background: rgb(212 205 223);
}

.Music-ControlButtons {
    fill: var(--TITLE_COLOR);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Music-ControlButtons svg {
    width: 20px;
    height: 20px;
}
.Music-ControlButtons button {
    background: rgb(255 255 255 / 0%);
    height: fit-content;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Music-ControlButtons .Play {
    margin: 0px 10px;
    width: 25px;
    height: 25px;
    position: relative;
}
.Music-ControlButtons .Play svg {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.Music-ControlButtons .PauseIcon {
    filter: blur(1px);
    opacity: 0;
    transform: scale(0.5);
}
.Music-ControlButtons .Back svg {
    transform: rotate(180deg);
}

/* Музыка - Полноценный плеер */

.Music-FullPlayer_BG {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    object-fit: cover;
}
.Music-FullPlayer_BG_Blur {
    background: rgb(39 39 39 / 20%);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
}

.Music-FullPlayer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    transform: translateY(200%);
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
}
.Music-FullPlayer .UI-ActionWindow {
    background: rgb(0 0 0 / 0%);
    border: 2px solid rgb(255 255 255 / 40%);
    max-width: 800px;
}
.Music-FullPlayer .UI-ActionWindow .TopBar .Title {
    color: rgb(255 255 255 / 40%);
    text-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
}
.Music-FullPlayer .UI-ActionWindow .TopBar button svg {
    fill: rgb(255 255 255 / 40%);
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 60%));
}
.Music-FullPlayer .UI-ActionWindow .UI-AW_Content {
    height: 55vh;
}
.Music-FullPlayer .UI-ActionWindow .UI-ScrollView {
    padding: 0px;
    border-radius: 15px;
    gap: 7px;
    display: flex;
    flex-direction: column;
}
.Music-FullPlayer .UI-ActionWindow .UI-AW_Content item {
    background: rgb(255 255 255 / 40%);
    color: rgb(0 0 0 / 50%);
    border-radius: 15px;
    font-size: 1.2em;
    padding: 20px;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    text-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
}
.Music-FullPlayer .UI-ActionWindow .UI-AW_Content item .InfoTitle {
    font-family: var(--FF_TITLE);
}
.Music-FullPlayer .UI-ActionWindow .UI-AW_Content item .Value {
    color: rgb(0 0 0 / 60%);
    text-align: center;
}
.Music-FullPlayer .CloseButton {
    background: rgb(255 255 255 / 0%);
    margin-top: 100px;
    z-index: 2;
    position: absolute;
    display: flex;
}
.Music-FullPlayer .CloseButton svg {
    fill: rgb(255 255 255 / 50%);
    width: 40px;
    height: 40px;
}

.Music-FullPlayer_C {
    width: 100%;
    max-width: 800px;
    height: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Music-FullPlayer_C .Cover-Containers {
    width: 250px;
    height: 250px;
    margin-right: 50px;
    flex-shrink: 0;
    position: relative;
}
.Music-FullPlayer_C .Cover-Containers .Cover,
.Music-FullPlayer_C .Cover-Containers .Music-NoneCover,
.Music-FullPlayer_C .Cover-Containers .Container {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
.Music-FullPlayer_C .Cover-Containers .Container {
    position: absolute;
    box-shadow: 0px 15px 35px -10px rgb(0 0 0 / 40%);
}
.Music-FullPlayer_C .Controls {
    fill: rgb(255 255 255 / 50%);
    margin-top: 5px;
    flex-grow: 1;
    z-index: 2;
}
.Music-FullPlayer_C .Controls .Info {
    gap: var(--PUSTOTA_WIDTH);
    display: flex;
    flex-direction: row;
}
.Music-FullPlayer_C .Controls .Info button {
    background: rgb(255 255 255 / 0%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.Music-FullPlayer_C .Controls .Info button svg {
    width: 20px;
    height: 20px;
    transition: .2s;
}
.Music-FullPlayer_C .Controls .Metadata {
    color: var(--AIR_COLOR);
    font-size: 1.3em;
    flex-grow: 1;
    text-shadow: 0px 1px 5px rgb(0 0 0 / 50%);
}
.Music-FullPlayer_C .Controls .Metadata .Name {
    font-family: var(--FF_TITLE);
}
.Music-FullPlayer_C .Controls .Metadata .Author {
    color: rgb(255 255 255 / 80%);
    font-size: 0.9em;
    margin-top: 5px;
    text-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
}
.Music-FullPlayer_C .Controls .Music-Slider {
    background: rgb(255 255 255 / 50%);
    border-radius: 2.5px;
    height: 6px;
    margin-top: 25px;
}
.Music-FullPlayer_C .Controls .Music-Slider .Progress {
    background: var(--AIR_COLOR);
}
.Music-FullPlayer_C .Controls .Music-Slider:hover {
    height: 10px;
    border-radius: 10px;
}
.Music-FullPlayer_C .Controls .Music-Duration {
    color: rgb(255 255 255 / 50%);
    font-size: 0.75em;
    font-family: var(--FF_TITLE);
    margin: 5px;
    user-select: none;
    display: flex;
    justify-content: space-between;
}
.Music-FullPlayer_C .Controls .Music-ControlButtons {
    fill: rgb(255 255 255 / 50%);
    margin: 10px 5px 0px;
    padding: 0px 10px;
    justify-content: space-between;
}
.Music-FullPlayer_C .Controls .Music-ControlButtons svg,
.Music-FullPlayer_C .Controls .Music-ControlButtons .Play {
    width: 25px;
    height: 25px;
}
.Music-FullPlayer_C .Controls .Music-ControlButtons .Base {
    fill: rgb(255 255 255);
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Music-FullPlayer_C .Controls .Music-ControlButtons .Base svg {
    width: 28px;
    height: 28px;
}
.Music-FullPlayer_C .Controls .Volume {
    margin-top: 10px;
    padding: 0px 5px;
    gap: 10px;
    display: flex;
    align-items: center;
}
.Music-FullPlayer_C .Controls .Volume .Music-Slider {
    background: rgb(255 255 255 / 50%);
    margin-top: 0px;
    height: 5px;
}
.Music-FullPlayer_C .Controls .Volume svg {
    fill: rgb(255 255 255 / 50%);
    height: 20px;
}
.Music-FullPlayer_C .Controls .Active svg {
    fill: var(--AIR_COLOR);
}

/* Музыка - Мини плеер */

.Music-MiniPlayer {
    background: var(--AIR_COLOR);
    border-radius: var(--BR_BASE);
    backdrop-filter: var(--AIR_BLUR);
    -webkit-backdrop-filter: var(--AIR_BLUR);
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 50px;
    z-index: 3;
    user-select: none;
    overflow: hidden;
    box-shadow: var(--BLOCK_SHADOW);
}

.Music-MiniPlayer_content {
    margin: 10px 13px;
    display: flex;
    align-items: center;
}
.Music-MiniPlayer .Cover {
    background: var(--AIR_BLOCK_COLOR);
    width: 50px;
    height: 50px;
    border-radius: 5px;
}
.Music-MiniPlayer .Cover:hover {
    cursor: pointer;
}
.Music-MiniPlayer .Music-NoneCover:hover {
    cursor: pointer;
}
.Music-MiniPlayer .Music-NoneCover {
    background: var(--AIR_BLOCK_COLOR);
    width: 50px;
    height: 50px;
    border-radius: 5px;
    flex-shrink: 0;
}
.Music-MiniPlayer_content .Metadata {
    flex-grow: 1;
    margin: 0px 15px 0px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.Music-MiniPlayer_content .Metadata:hover {
    cursor: pointer;
}
.Music-MiniPlayer_content .Name,
.Music-MiniPlayer_content .Author {
    width: fit-content;
    max-width: 100%;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Music-MiniPlayer_content .Author {
    color: var(--D_OR_P_COLOR);
    font-size: 0.9em;
}
.Music-MiniPlayer_content .SliderContainer {
    width: 100%;
    margin-top: 5px;
    gap: 5px;
    display: flex;
    align-items: center;
}
.Music-MiniPlayer_content .SliderContainer .Music-Slider {
    margin: 0;
}
.Music-MiniPlayer_content .SliderContainer .Duration {
    font-size: 0.6em;
    opacity: 0.7;
}

/* Музыка - Добавить */

.Music-Add {
    display: flex;
}
.Music-Add button {
    background: var(--BLOCK_COLOR);
    padding: 8px 15px;
    margin-left: 10px;
    text-wrap: nowrap;
    position: sticky;
    z-index: 2;
    display: flex;
    align-items: center;
    box-shadow: var(--BLOCK_SHADOW);
}
.Music-Add button svg,
.Music-Add button img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    margin-left: -5px;
}
.Music-Add button svg {
    fill: var(--BTN_TEXT_COLOR);
}

.Music-AddFrom_content input,
.Music-AddFrom_content textarea {
    background: var(--AIR_BLOCK_COLOR);
    padding: 10px;
    user-select: auto;
}
.Music-AddFrom_content textarea {
    height: 100%;
    resize: none;
}
.Music-AddFrom_content input:hover, 
.Music-AddFrom_content input:focus,
.Music-AddFrom_content textarea:hover,
.Music-AddFrom_content textarea:focus {
    outline: 2px solid var(--AIR_BLOCK_COLOR);
}
.Music-AddFrom_content input[type="file"] {
    display: none;
}
.Music-AddFrom_content label {
    cursor: pointer;
}
.Music-AddFrom_content label:active {
    filter: brightness(0.8);
}
.Music-AddFrom_content label .Text {
    width: 85%;
    font-size: var(--FS_TEXT);
    text-wrap: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Music-AddFrom_content .Inputs-Title {
    color: var(--D_OR_P_COLOR);
    font-size: 0.85em;
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.Music-AddFrom_content .BaseInfo {
    margin: 0px 5px;
    display: flex;
}
.Music-AddFrom_content .BaseInfo .Cover {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: 10px;
    width: 150px;
    height: 150px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.Music-AddFrom_content .BaseInfo .Cover svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 80px;
    height: 80px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
}
.Music-AddFrom_content .BaseInfo .Cover .Text {
    position: absolute;
    bottom: 10px;
}
.Music-AddFrom_content .BaseInfo .Inputs {
    flex-grow: 1;
    gap: 7px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.Music-AddFrom_content .InfoAndFile {
    margin: 10px 5px;
    display: flex;
}
.Music-AddFrom_content .InfoAndFile .Info {
    font-size: 0.9em;
    margin-left: 10px;
}
.Music-AddFrom_content .InfoAndFile label {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: 10px;
    width: 150px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Music-AddFrom_content .AllInfo {
    margin: 10px 5px 5px;
    display: flex;
    flex-direction: column;
}
.Music-AddFrom_content .AllInfo .Columns {
    gap: 10px;
    display: flex;
    flex-direction: row;
}
.Music-AddFrom_content .AllInfo .Column {
    margin-top: 8px;
    flex-grow: 1;
    gap: 7px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.Music-AddFrom_content .Send {
    background: var(--ACCENT_COLOR);
    color: #FFF;
    padding: 8px 15px;
    margin: 15px auto 5px;
    display: block;
}

/* Музыка - Список */

.Music-List {
    position: relative;
}
.Music-List .Title {
    font-size: 1.2em;
    font-family: var(--FF_TITLE);
    margin: 14px 20px;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 1;
}
.Music-List .Scroll {
    overflow-x: scroll;
    display: flex;
}
.Music-List .SH_L {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: 1px 0px 9px 10px var(--BODY_COLOR);
}
.Music-List .SH_R {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    box-shadow: 1px 0px 9px 10px var(--BODY_COLOR);
}

.Music-List .ScrollButton {
    background: var(--AIR_COLOR);
    width: fit-content;
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Music-List .ScrollButton svg {
    fill: var(--BTN_TEXT_COLOR);
    width: 35px;
    height: 35px;
    margin: 0px -10px;
}
.Music-List .SC-R {
    right: 0;
}
.Music-List .SC-R svg {
    transform: scaleX(-1);
}

.Music-SongPrew {
    width: fit-content;
    position: relative;
    user-select: none;
    margin: 50px 15px 10px 15px;
}
.Music-SongPrew .Cover {
    position: relative;
    cursor: pointer;
    transition: .2s;
}
.Music-SongPrew .Cover .Music-NoneCover {
    margin-bottom: 5px;
}
.Music-SongPrew .Cover:hover {
    transform: scale(0.95);
}
.Music-SongPrew .Cover img {
    background: var(--AIR_BLOCK_COLOR);
    border-radius: 10px;
    width: 150px;
    height: 150px;
}
.Music-SongPrew .Cover .UI-PRELOAD {
    border-radius: 10px;
    width: 150px;
    height: 150px;
}
.Music-SongPrew .Cover .UI-PRELOAD::after {
    filter: blur(27px);
}
.Music-SongPrew .Cover .CoverShadow {
    background: rgb(255 255 255 / 0%);
    border-radius: 0px;
    width: 140px;
    height: 145px;
    filter: blur(10px);
    margin: auto;
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.Music-SongPrew .MetaAndButton {
    max-width: 140px;
    margin: 2px 5px 8px 5px;
    display: flex;
    align-items: center;
}
.Music-SongPrew .UI-GovernButton {
    background: rgb(255 255 255 / 0%);
    position: sticky;
    z-index: 1;
}
.Music-SongPrew .UI-GovernButtons {
    width: 100%;
    left: 0px;
    right: 0px;
    top: unset;
    bottom: 46px;
    z-index: 1;
}
.Music-SongPrew .Metadata {
    flex: 1;
    max-width: 119px;
    white-space: nowrap;
}
.Music-SongPrew .Metadata .UI-PRELOAD {
    width: 100%;
    height: 12px;
    margin-top: 2px;
}
.Music-SongPrew .Metadata .UI-PRELOAD::after {
    filter: blur(12px);
}
.Music-SongPrew .Name {
    overflow: hidden;
    text-overflow: ellipsis;
}
.Music-SongPrew .Author {
    color: var(--D_OR_P_COLOR);
    font-size: 0.9em;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-device-width: 768px),
only screen and (device-width: 768px) and (device-height: 768px),
only screen and (width: 768px) and (orientation: landscape),
only screen and (device-width: 768px),
only screen and (max-width: 768px) {

    /* Главное */

    :root {
        --FS_TEXT: 0.96em;
        --FS_D_OR_P: 0.85em;
    }

    /* Авторизация */

    .Auth-Body {
        width: 100%;
        height: 100%;
        box-shadow: none;
        border-radius: 0px;

        .Left, .Right {
            width: 100%;
            height: 100%;
            min-height: 100%;
            position: absolute;
        }

        .LogoAndTitle {
            display: none;
        }

        .Login, .Reg, .Verify, .Auth-Container {
            width: 90%;
        }
    }

    /* Панель навигации */

    .UI-N_DIV {
        gap: 15px;
        padding: 0px 30px;
    }

    .UI-NM_Content {
        width: 95%;
        padding: 50px 0px 90px;
    }
    .UI-NM_Content .Notifications {
        margin: 130px auto 7px auto;
    }

    /* Интерфейс */
    
    .UI-L_NAV {
        background: var(--AIR_COLOR);
        backdrop-filter: var(--AIR_BLUR);
        -webkit-backdrop-filter: var(--AIR_BLUR);
        max-width: 100%;
        height: 80px;
        padding: 0px 30px;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-shadow: 0px -4px 10px -9px rgb(64 64 64 / 50%);
        -moz-box-shadow: 0px -4px 10px -9px rgb(64 64 64 / 50%);
        -webkit-box-shadow: 0px -4px 10px -9px rgb(64 64 64 / 50%);

        .UI-B_FIRST {
            margin-top: 0px;
        }
        .GoldText {
            flex-direction: column;
        }
        .PAGE-EPACK {
            display: none;
        }
    }
    .UI-L_NAV .UI-LN_ICON {
        width: 25px;
        height: 25px;
        font-size: 25px;
        margin: 0px 0px 5px 0px;
    }
    .UI-L_NAV button {
        background: rgb(255 255 255 / 0%);
        margin: 0;
        width: 60px;
        height: 80%;
        font-size: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .UI-L_NAV .UI-NCounter {
        top: 5px;
        font-size: 1em;
    }
    .UI-L_NAV .ActiveButton {
        background: var(--AIR_COLOR);
    }

    .Content {
        width: 95%;
        height: 100%;
        margin: auto;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .Content-Center {
        align-items: center;
        justify-content: center;
    }

    .UI-PAGE_BODY {
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: scroll;
    }

    .UI-ScrollView {
        padding: 0px 0px 90px;
    }

    .UI-PB_PRELOAD {
        position: fixed;
        margin: 0;
    }

    header {
        height: 80px;
    }
    .Search-Container {
        width: 100%;
    }
    .Search-Container .EBalls {
        display: none;
    }

    .UI-NavPanel {
        top: 70px;
        right: -20px;
    }

    .UI-N_L_AND_N {
        width: 90%;
        
        .UI-Logo {
            margin-right: 10px;
        }
    }

    .UI-C_L, .UI-C_R {
        margin: 0;
        width: 100%;
        height: fit-content;
        overflow: unset;
        max-width: 100%;
    }

    .UI-B_FIRST {
        margin-top: 90px;
    }

    .UI-Block {
        width: 100%;
    }

    .UI-Window_BTNS {
        height: 35px;
    }

    .UI-PartitionBody {
        position: fixed;
    }

    .UI-ActionWindow {
        width: 95%;
        max-width: 95%;
    }

    .UI-Tooltip {
        position: relative;
    }

    .UI-Tooltip .Box {
        position: absolute;
        background: var(--AIR_COLOR);
        backdrop-filter: var(--AIR_BLUR);
        color: #fff;
        padding: 5px;
        border-radius: var(--BR_BASE);
        top: calc(100% + 5px);
        display: none;
    }

    .UI-Tooltip .Arrow {
        position: absolute;
        top: -10px;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent var(--AIR_BLUR) transparent;
    }

    .UI-Tooltip .Visible {
        display: block;
      }

    /* Поиск */

    .Search-Result {
        width: 95%;
        margin: 90px auto auto auto;
    }

    /* Вход (Мобильная версия) */

    .Form_Container {
        padding: 15px;
        width: 88%;
        max-width: 88%;
    }

    .Authorization-Form input {
        margin-bottom: 13px;
    }

    .Home-Status {
        display: none;
    }

    /* Главная страница */

    .HomePage .UI-C_R {
        display: none;
    }

    /* Пост */

    .AddPost .Buttons .AddFileButtons .FileName {
        max-width: 70px;
    }

    .Post-Page {
        width: 95%;
        height: fit-content;
        margin: auto;

        .UI-C_L, .UI-C_R {
            width: 100%;
            height: fit-content;
        }
        .UI-C_L .UI-ScrollView {
            padding: 0;
        }
        .UI-C_R .UI-B_FIRST {
            margin: 0;
        }
    }

    .Post {
        width: auto;
        min-width: auto;
        .InteractionButton svg {
            width: 23px;
            height: 23px;
        }
    }
    .Post .File .FileInfo {
        max-width: 50%;
    }

    /* Профиль */

    .Profile-Page .UI-C_R .UI-B_FIRST {
        margin: 0px 0px 7px 0px;
    }
    .Profile-Page .UI-C_L {
        width: 100%;
    }
    .Profile-Page .UI-C_L .UI-ScrollView {
        overflow: unset;
        padding: 0;
    }

    .Profile-InfoBlock {
        .UI-UserIcons svg, .UI-UserIcons img {
            width: 20px;
            height: 20px;
        }
    }

    .Profile-Subs {
        padding: 0;
    }

    /* Настройки */

    .Settings-Page .UI-C_R {
        display: none;
    }

    /* Подписка */

    .GoldSub-Page .UI-C_R {
        display: none;
    }
    .GoldSub-Page .UI-ScrollView {
        padding-bottom: 200px;
    }

    .GoldSub-Buttons {
        position: fixed;
        width: 95%;
        bottom: 90px;
    }

    .GoldSub-VideoPrew,
    .GoldSub-InfoPrew {
        width: 95%;
        margin: auto;
        position: fixed;
        bottom: 60px;
    }

    /* Просмотр EPACK */

    .EPACK-Page .UI-C_R {
        display: none;
    }

    /* Музыка */

    .Music-FullPlayer_C {
        width: 85%;
        padding: 0;
        justify-content: center;
        flex-direction: column;
    }
    .Music-FullPlayer_C .Controls .Music-ControlButtons {
        margin-right: 0;
        margin-left: 0;
    }
    .Music-FullPlayer_C .Cover-Containers {
        width: 39vh;
        height: 39vh;
        margin: 0px auto 25px auto;
    }
    .Music-FullPlayer_C .Controls {
        width: 100%;
        flex-grow: 0;
    }

    .Music-MiniPlayer {
        bottom: 90px;
    }

}

@media only screen and (max-device-width: 768px),
only screen and (device-width: 768px) and (device-height: 768px),
only screen and (width: 768px) and (orientation: landscape),
only screen and (device-width: 768px),
only screen and (max-width: 768px) {

    /* Чат */

    .Messanger {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
    }

    .Chat,
    .Chats {
        border-radius: 0px;
        width: 100%;
        max-width: 100%;
        position: absolute;
        right: 0;
        left: 0;
    }

    .Chat {
        transform: translateX(100%);
    }

    .Chats-Title,
    .Chat-TopBar,
    .Chat-DownBar {
        height: 70px;
    }

    .Chat-TopWarning {
        margin-top: 80px;
    }

    .Chat-Messanges {
        width: 100%;
    }

    .Chat-Messanges_list {
        padding: 90px 0px;
    }

    .Chats {
        background: var(--BODY_COLOR);
    }

    .Chats-List {
        width: 95%;
        margin: 80px auto auto auto;
    }

    .Chats-User {
        background: var(--BLOCK_COLOR);
        max-width: 100%;
        margin: 0px 0px 7px 0px;
    }

    /* Анимации */

    @keyframes IMAGE_VIEW-FULLSCREAN {
        0% {
            transform: translateY(0%);
            opacity: 1;
            visibility: visible;
            bottom: 60px;
            max-width: 95%;
            height: 500px;
            border-radius: var(--BR_BASE);
        }

        100% {
            transform: translateY(0%);
            opacity: 1;
            visibility: visible;
            bottom: 0px;
            max-width: 100%;
            height: 100%;
            border-radius: 0px;
        }
    }

    @keyframes IMAGE_VIEW-DISABLE_FULLSCREAN {
        0% {
            transform: translateY(0%);
            opacity: 1;
            visibility: visible;
            bottom: 0px;
            max-width: 100%;
            height: 100%;
            border-radius: 0px;
        }

        100% {
            transform: translateY(0%);
            opacity: 1;
            visibility: visible;
            bottom: 60px;
            max-width: 95%;
            height: 500px;
            border-radius: var(--BR_BASE);
        }
    }
}