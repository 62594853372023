.TH-Container {
    border-radius: 8px;
    width: 75px;
    height: 120px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.TH-Container:active {
    filter: brightness(0.8);
}

.TH-TopBar, .TH-BottomBar {
    width: 100%;
    height: 15px;
    position: absolute;
    z-index: 1;
}

.TH-TopBar {
    top: 0;
}

.TH-BottomBar {
    bottom: 0;
    border-top: solid 1px #e6e6e6;
}

.TH-Posts {
    width: 100%;
    height: 100%;
}

.TH-AddPost, .TH-Post {
    border-radius: 2px;
    width: auto;
    height: 30px;
}

.TH-AddPost {
    margin: 18px 4px 0px;
    position: relative;
}

.TH-Button {
    border-radius: 2px;
    width: 25px;
    height: 6px;
    margin: 3.5px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.TH-Post {
    margin: 2.5px 4px 0px;
}


.Theme-Light {
    .TH-Container {
        background: #e6e6e6;
    }

    .TH-TopBar, .TH-BottomBar {
        background: #f8f8f8;
    }

    .TH-BottomBar {
        border-top: solid 1px #e6e6e6;
    }

    .TH-AddPost, .TH-Post {
        background: #f8f8f8;
    }

    .TH-Button {
        background: #9674ca;
    }
}

.Theme-Gold {
    .TH-Container {
        background: #e6e6e6;
    }

    .TH-TopBar, .TH-BottomBar {
        background: #f8f8f8;
    }

    .TH-BottomBar {
        border-top: solid 1px #e6e6e6;
    }

    .TH-AddPost, .TH-Post {
        background: #f8f8f8;
    }

    .TH-Button {
        background: linear-gradient(465deg, #fab31e, #fd9347);
    }
}

.Theme-Dark {
    .TH-Container {
        background: #141414;
    }

    .TH-TopBar, .TH-BottomBar {
        background: #424242;
    }

    .TH-BottomBar {
        border-top: solid 1px #141414;
    }

    .TH-AddPost, .TH-Post {
        background: #424242;
    }

    .TH-Button {
        background: #9674ca;
    }
}

.Theme-Gold-Dark {
    .TH-Container {
        background: #141414;
    }

    .TH-TopBar, .TH-BottomBar {
        background: #424242;
    }

    .TH-BottomBar {
        border-top: solid 1px #141414;
    }

    .TH-AddPost, .TH-Post {
        background: #424242;
    }

    .TH-Button {
        background: linear-gradient(465deg, #fab31e, #fd9347);
    }
}

.Theme-Amoled {
    .TH-Container {
        background: #000;
    }

    .TH-TopBar, .TH-BottomBar {
        background: #141414;
    }

    .TH-BottomBar {
        border-top: solid 1px #141414;
    }

    .TH-AddPost, .TH-Post {
        background: #101010;
    }

    .TH-Button {
        background: #9674ca;
    }
}

.Theme-Amoled-Gold {
    .TH-Container {
        background: #000;
    }

    .TH-TopBar, .TH-BottomBar {
        background: #141414;
    }

    .TH-BottomBar {
        border-top: solid 1px #141414;
    }

    .TH-AddPost, .TH-Post {
        background: #101010;
    }

    .TH-Button {
        background: linear-gradient(465deg, #fab31e, #fd9347);
    }
}