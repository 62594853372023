/* Загрузчик - 1 */

.UI-Loader_1 {
  --uil1-size: 35px;
  --uil1-color: black;
  --uil1-speed: 1.5s;
  --uil1-dot-size: calc(var(--uil1-size) * 0.4);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uil1-size);
  width: var(--uil1-size);
  animation: uil1_anim2 calc(var(--uil1-speed) * 1.667) infinite linear;
}

.UI-Loader_1::before,
.UI-Loader_1::after {
  content: "";
  position: absolute;
  height: var(--uil1-dot-size);
  width: var(--uil1-dot-size);
  border-radius: 50%;
  background-color: var(--uil1-color);
  flex-shrink: 0;
  transition: background-color 0.3s ease;
}

.UI-Loader_1::before {
  animation: uil1_anim var(--uil1-speed) linear infinite;
}

.UI-Loader_1::after {
  animation: uil1_anim var(--uil1-speed) linear calc(var(--uil1-speed) / -2)
    infinite;
}

@keyframes uil1_anim2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes uil1_anim {
  0% {
    transform: translateX(calc(var(--uil1-size) * 0.25)) scale(0.73684);
    opacity: 0.65;
  }
  5% {
    transform: translateX(calc(var(--uil1-size) * 0.235)) scale(0.684208);
    opacity: 0.58;
  }
  10% {
    transform: translateX(calc(var(--uil1-size) * 0.182)) scale(0.631576);
    opacity: 0.51;
  }
  15% {
    transform: translateX(calc(var(--uil1-size) * 0.129)) scale(0.578944);
    opacity: 0.44;
  }
  20% {
    transform: translateX(calc(var(--uil1-size) * 0.076)) scale(0.526312);
    opacity: 0.37;
  }
  25% {
    transform: translateX(0%) scale(0.47368);
    opacity: 0.3;
  }
  30% {
    transform: translateX(calc(var(--uil1-size) * -0.076)) scale(0.526312);
    opacity: 0.37;
  }
  35% {
    transform: translateX(calc(var(--uil1-size) * -0.129)) scale(0.578944);
    opacity: 0.44;
  }
  40% {
    transform: translateX(calc(var(--uil1-size) * -0.182)) scale(0.631576);
    opacity: 0.51;
  }
  45% {
    transform: translateX(calc(var(--uil1-size) * -0.235)) scale(0.684208);
    opacity: 0.58;
  }
  50% {
    transform: translateX(calc(var(--uil1-size) * -0.25)) scale(0.73684);
    opacity: 0.65;
  }
  55% {
    transform: translateX(calc(var(--uil1-size) * -0.235)) scale(0.789472);
    opacity: 0.72;
  }
  60% {
    transform: translateX(calc(var(--uil1-size) * -0.182)) scale(0.842104);
    opacity: 0.79;
  }
  65% {
    transform: translateX(calc(var(--uil1-size) * -0.129)) scale(0.894736);
    opacity: 0.86;
  }
  70% {
    transform: translateX(calc(var(--uil1-size) * -0.076)) scale(0.947368);
    opacity: 0.93;
  }
  75% {
    transform: translateX(0%) scale(1);
    opacity: 1;
  }
  80% {
    transform: translateX(calc(var(--uil1-size) * 0.076)) scale(0.947368);
    opacity: 0.93;
  }
  85% {
    transform: translateX(calc(var(--uil1-size) * 0.129)) scale(0.894736);
    opacity: 0.86;
  }
  90% {
    transform: translateX(calc(var(--uil1-size) * 0.182)) scale(0.842104);
    opacity: 0.79;
  }
  95% {
    transform: translateX(calc(var(--uil1-size) * 0.235)) scale(0.789472);
    opacity: 0.72;
  }
  100% {
    transform: translateX(calc(var(--uil1-size) * 0.25)) scale(0.73684);
    opacity: 0.65;
  }
}

/* Загрузчик - 2 */

.UI-Loader_2 {
  --uil2-size: 80px;
  --uil2-color: black;
  --uil2-speed: 1.4s;
  --uil2-stroke: 5px;
  --uil2-bg-opacity: .1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uil2-stroke);
  width: var(--uil2-size);
  border-radius: calc(var(--uil2-stroke) / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.UI-Loader_2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--uil2-color);
  opacity: var(--uil2-bg-opacity);
  transition: background-color 0.3s ease;
}

.UI-Loader_2::after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: calc(var(--uil2-stroke) / 2);
  animation: zoom var(--uil2-speed) ease-in-out infinite;
  transform: translateX(-100%);
  background-color: var(--uil2-color);
  transition: background-color 0.3s ease;
}

@keyframes zoom {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
