/*/ Поебота для анимаций /*/

.ParticleContainer {
    position: fixed;
    top: 0;
    z-index: 50;
}

/* Комментарии */

.UI-Particle,
.UI-ImageParticle,
.UI-TextParticle  {
    border-radius: 100px;
    position: absolute;
    animation: Disintegrate 1.5s forwards;
}

.UI-Particle {
    background: var(--BLOCK_COLOR);
}
.UI-TextParticle  {
    background: var(--TEXT_COLOR);
}

@keyframes Disintegrate {
    to {
        opacity: 0;
        transform: translate(calc(var(--x) * 1px), calc(var(--y) * 1px));
    }
}

/*/ Анимации /*/

/* Авторизация */

@keyframes AUTH-SHOW_REG {
    0% {
        transform: translateX(150%) scale(0.5);
        opacity: 0.1;
        visibility: visible;
    }
    100% {
        transform: translateX(0) scale(1);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes AUTH-HIDE_REG {
    0% {
        transform: translateX(0) scale(1);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: translateX(150%) scale(0.5);
        opacity: 0.1;
        visibility: visible;
    }
}

@keyframes AUTH-SHOW_LOGIN {
    0% {
        transform: translateX(-150%) scale(0.5);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        transform: translateX(0) scale(1);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes AUTH-HIDE_LOGIN {
    0% {
        transform: translateX(0) scale(1);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: translateX(-150%) scale(0.5);
        opacity: 0;
        visibility: hidden;
    }
}


/* Глобально - Окно информации */

@keyframes WINDOW-SHOW {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0.1;
        visibility: visible;
        -webkit-box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        visibility: visible;
        box-shadow: var(--AIR_SHADOW);
        -moz-box-shadow: var(--AIR_SHADOW);
        -webkit-box-shadow: var(--AIR_SHADOW);
    }
}

@keyframes WINDOW-SHOW_BG {
    0% {
        opacity: 0;
        visibility: visible;
    }
    
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes WINDOW-HIDE {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        visibility: visible;
        box-shadow: var(--AIR_SHADOW);
        -moz-box-shadow: var(--AIR_SHADOW);
        -webkit-box-shadow: var(--AIR_SHADOW);
    }

    100% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0;
        visibility: hidden;
        -webkit-box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
    }
}

@keyframes WINDOW-HIDE_BG {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

/* Глобально - Блюр */

@keyframes BLUR-SHOW {
    0% {
        opacity: 0;
        visibility: visible;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }
    50% {
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
    100% {
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
}

@keyframes BLUR-HIDE {
    0% {
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
    50% {
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
    100% {
        opacity: 0;
        visibility: hidden;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }
}

/* Панель навигации */

@keyframes NAV_PANEL-SHOW {
    0% {
        transform: translateY(-101%);
    }
    100% {
        transform: translateY(0%);
    }
}
@keyframes NAV_PANEL-HIDE {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-101%);
    }
}

@keyframes NAV_PANEL_BUTTONS-SHOW {
    0% {
        transform: rotate3d(10, -10, 1, 45deg)translate(25px, -100px)scale(0.3);
        opacity: 0;
        visibility: visible;
    }
    100% {
        transform: rotate3d(1, 1, 1, 0deg)translate(0px, 0px)scale(1);
        opacity: 1;
        visibility: visible;
    }
}
@keyframes NAV_PANEL_BUTTONS-HIDE {
    0% {
        transform: rotate3d(1, 1, 1, 0deg)translate(0px, 0px)scale(1);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: rotate3d(10, -10, 1, 45deg)translate(25px, -100px)scale(0.3);
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes NAV_PANEL-DISABLE_BLUR {
    0% {
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
    100% {
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }
}
@keyframes NAV_PANEL-ENABLE_BLUR {
    0% {
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
}

@keyframes BOTTOM_NAV-SHOW {
    0% {
        transform: translateY(101%);
    }
    100% {
        transform: translateY(0%);
    }
}
@keyframes BOTTOM_NAV-HIDE {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(101%);
    }
}

@keyframes V2-ELEMENT-SHOW {
    0% {
        opacity: 0;
        visibility: visible;
        filter: blur(1px);
    }
    100% {
        opacity: 1;
        visibility: visible;
        filter: blur(0px);
    }
}
@keyframes V2-ELEMENT-HIDE {
    0% {
        opacity: 1;
        visibility: visible;
        filter: blur(1px);
    }
    100% {
        opacity: 0;
        visibility: hidden;
        filter: blur(0px);
    }
}

@keyframes V3-ELEMENT-SHOW {
    0% {
        transform: translateY(200%);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
    }
}
@keyframes V3-ELEMENT-HIDE {
    0% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: translateY(200%);
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes V4-ELEMENT-SHOW {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes V4-ELEMENT-HIDE {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Глобально - Поиск */

@keyframes SEARCH-SHOW {
    0% {
        transform: translateY(-150%);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
    }
}
@keyframes SEARCH-HIDE {
    0% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: translateY(-150%);
        opacity: 0;
        visibility: hidden;
    }
}

/* Глобально - Открытие раздела */

@keyframes PARTITION_PAGE-SHOW {
    0% {
        visibility: visible;
        transform: translateY(100%);
    }
    100% {
        visibility: visible;
        transform: translateY(0%);
    }
}
@keyframes PARTITION_PAGE-HIDE {
    0% {
        visibility: visible;
        transform: translateY(0%);
    }
    100% {
        visibility: hidden;
        transform: translateY(100%);
    }
}

@keyframes SCROLL_VIEW-SHOW {
    0% {
        transform: scale(0.95);
        filter: brightness(0.9);
        overflow: hidden;
    }
    100% {
        transform: scale(1);
        filter: brightness(1);
        overflow: scroll;
    }
}
@keyframes SCROLL_VIEW-HIDE {
    0% {
        transform: scale(1);
        filter: brightness(1);
        overflow: scroll;
    }
    100% {
        transform: scale(0.95);
        filter: brightness(0.9);
        overflow: hidden;
    }
}

/* Интерфейс - Просмотр изображения */

@keyframes IMAGE_VIEW-SHOW {
    0% {
        transform: translateY(150%);
        opacity: 0;
        visibility: visible;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
    }
}
@keyframes IMAGE_VIEW-HIDE {
    0% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: translateY(150%);
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes IMAGE_VIEW-FULLSCREAN {
    0% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
        bottom: 60px;
        max-width: 850px;
        height: 500px;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
        bottom: 0px;
        max-width: 100%;
        height: 100%;
    }
}
@keyframes IMAGE_VIEW-DISABLE_FULLSCREAN {
    0% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
        bottom: 0px;
        max-width: 100%;
        height: 100%;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
        bottom: 60px;
        max-width: 850px;
        height: 500px;
    }
}

@keyframes IMAGE_VIEW-DF_BUTTON-SHOW {
    0% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}
@keyframes IMAGE_VIEW-DF_BUTTON-HIDE {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes IMAGE_VIEW-DARK_BLUR {
    0% {
        background: rgb(255 255 255 / 0%);
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }
    100% {
        background: rgb(0 0 0 / 70%);
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
    }
}
@keyframes IMAGE_VIEW-WHITE_BLUR {
    0% {
        background: rgb(0 0 0 / 70%);
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
    }
    100% {
        background: rgb(255 255 255 / 0%);
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }
}

/* ЧатиГ */

@keyframes CHAT-CHATS_HIDE {
    0% {
        width: 60%;
    }
    100% {
        width: 0%;
    }
}
@keyframes CHAT-CHATS_SHOW {
    0% {
        width: 0%;
    }
    100% {
        width: 60%;
    }
}

@keyframes CHAT-SHOW {
    0% {
        width: 100%;
        -webkit-box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
        -moz-box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
        box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
    }
    100% {
        width: 50%;
        -webkit-box-shadow: 0px 4px 10px -9px rgb(64 64 64 / 70%);
        -moz-box-shadow: 0px 4px 10px -9px rgb(64 64 64 / 70%);
        box-shadow: 0px 4px 10px -9px rgb(64 64 64 / 70%);
    }
}
@keyframes CHAT-HIDE {
    0% {
        width: 50%;
        -webkit-box-shadow: 0px 4px 10px -9px rgb(64 64 64 / 70%);
        -moz-box-shadow: 0px 4px 10px -9px rgb(64 64 64 / 70%);
        box-shadow: 0px 4px 10px -9px rgb(64 64 64 / 70%);
    }
    100% {
        width: 100%;
        -webkit-box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
        -moz-box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
        box-shadow: 0px 2px 7px 0px rgb(200 200 200 / 50%);
    }
}

@keyframes CHAT-MOBILE_SHOW {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}
@keyframes CHAT-MOBILE_HIDE {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes CHAT-HIDE_WARNING {
    0% {
        transform: translate(0) scale(1);
        opacity: 1;
        border-radius: var(--BR_BASE);
    }
    40% {
        transform: translate(0) scale(0.9);
        opacity: 1;
        border-radius: var(--BR_BASE);
        box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
    }
    100% {
        transform: translateY(-100px) scale(0.9);
        opacity: 0.1;
        border-radius: var(--BR_BASE);
        box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
    }
}

@keyframes CHAT-SHOW_WARNING {
    0% {
        transform: translateY(-100px) scale(0.9);
        opacity: 0.1;
        border-radius: var(--BR_BASE);
        box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
    }
    40% {
        transform: translate(0) scale(0.9);
        opacity: 1;
        border-radius: var(--BR_BASE);
        box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
    }
    100% {
        transform: translate(0) scale(1);
        opacity: 1;
    }
}

@keyframes CHAT-SHOW_ERROR {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes CHAT-HIDE_ERROR {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes ELEMENT-SHOW {
    0% {
        opacity: 0;
        visibility: visible;
        filter: blur(1px);
        -webkit-box-shadow: 0px 0px 10px 0px rgb(17 17 17 / 0%);
        -moz-box-shadow: 0px 0px 10px 0px rgb(17 17 17 / 0%);
        box-shadow: 0px 0px 10px 0px rgb(17 17 17 / 0%);
    }

    100% {
        opacity: 1;
        visibility: visible;
        filter: blur(0px);
        box-shadow: var(--AIR_SHADOW);
        -moz-box-shadow: var(--AIR_SHADOW);
        -webkit-box-shadow: var(--AIR_SHADOW);
    }
}
@keyframes ELEMENT-HIDE {
    0% {
        opacity: 1;
        visibility: visible;
        filter: blur(1px);
        box-shadow: var(--AIR_SHADOW);
        -moz-box-shadow: var(--AIR_SHADOW);
        -webkit-box-shadow: var(--AIR_SHADOW);
    }

    100% {
        opacity: 0;
        visibility: hidden;
        filter: blur(0px);
        -webkit-box-shadow: 0px 0px 10px 0px rgb(17 17 17 / 0%);
        -moz-box-shadow: 0px 0px 10px 0px rgb(17 17 17 / 0%);
        box-shadow: 0px 0px 10px 0px rgb(17 17 17 / 0%);
    }
}

@keyframes AP-FILE_SETTINGS-ACTIVE {
    0% {
        background: var(--BTN_BACKGROUND);
        box-shadow: 0px 0px 0px 0px rgb(31 30 30 / 0%);
    }
    100% {
        background: var(--AIR_COLOR);
        box-shadow: 0px 0px 10px -1px rgb(31 30 30 / 30%);
    }
}
@keyframes AP-FILE_SETTINGS-NOTACTIVE {
    0% {
        background: var(--AIR_COLOR);
        box-shadow: 0px 0px 10px -1px rgb(31 30 30 / 30%);
    }
    100% {
        background: var(--BTN_BACKGROUND);
        box-shadow: 0px 0px 0px 0px rgb(31 30 30 / 0%);
    }
}

/* Профиль */

@keyframes PROFILE_ICON_INFO {
    0% {
        margin: 0px 0px 0px 3px;
        transform: scale(1);
    }

    100% {
        margin: 0px 15px;
        transform: scale(1.5);
    }
}
@keyframes HD_PROFILE_ICON_INFO {
    0% {
        margin: 0px 15px;
        transform: scale(1.5);
    }

    100% {
        margin: 0px 0px 0px 3px;
        transform: scale(1);
    }
}

@keyframes LOAD_PAGE {
    0%, 25% {
      opacity: 0;
    }

    50%, 75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
}

@keyframes PAGE_LOADED {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

/* Файлы */

/* Файлы - Удалить файл */

@keyframes FILE_INPUT-DELETE {
    0% {
        transform: scale3d(1, 1, 1);
    }
    100% {
        height: 0px;
        transform: scale3d(1, 0, 1);
    }
}

/* Настройки */

@keyframes SETTINGS-SHOW_INPUT {
    0% {
        transform: translate(0) scale(0.5);
        opacity: 0.1;
        top: -40px;
        box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
    }
    40% {
        transform: translate(0) scale(0.5);
        opacity: 1;
        box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
    }
    100% {
        transform: translate(0) scale(1);
        opacity: 1;
        top: 5px;
        box-shadow: var(--AIR_SHADOW);
        -moz-box-shadow: var(--AIR_SHADOW);
        -webkit-box-shadow: var(--AIR_SHADOW);
    }
}

@keyframes SETTINGS-HIDE_INPUT {
    0% {
        transform: translate(0) scale(1);
        opacity: 1;
        top: 5px;
        box-shadow: var(--AIR_SHADOW);
        -moz-box-shadow: var(--AIR_SHADOW);
        -webkit-box-shadow: var(--AIR_SHADOW);
    }
    40% {
        transform: translate(0) scale(0.5);
        opacity: 1;
        box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 1px 5px -6px rgb(114 114 114 / 50%);
    }
    100% {
        transform: translate(0) scale(0.5);
        opacity: 0.1;
        top: -40px;
        box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -moz-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
        -webkit-box-shadow: 0px 0px 0px 0px rgb(114 114 114 / 50%);
    }
}

/* Пост */

/* Пост - Показать текст */

@keyframes POST-SHOW_TEXT {
    0% {
        max-height: 300px;
    }
    100% {
        max-height: 10000px;
    }
}

/* Пост - Поделиться */

@keyframes POST-SHOW_SHARE {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes POST-HIDE_SHARE {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

/* Пост - Показать прогресс */

@keyframes POST-SHOW_PROGRESS {
    0% {
        margin-top: 0px;
        height: 0px;
        opacity: 0;
    }
    100% {
        height: 5px;
        margin-top: 7px;
        opacity: 1;
    }
}

/* Пост - Показать изображение */

@keyframes POST-CI_HIDE {
    0% {
        filter: blur(0px);
        opacity: 1;
    }
    50% {
        filter: blur(10px);
        opacity: 0.6;
    }
    100% {
        filter: blur(10px);
        opacity: 0;
    }
}
@keyframes POST-SHOW_IMAGE {
    0% {
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        opacity: 1;
        visibility: visible;
    }
    50% {
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        opacity: 0.6;
        visibility: visible;
    }
    100% {
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        opacity: 0;
        visibility: hidden;
    }
}

/* Подписка Gold */

@keyframes INFO_SUB-SHOW {
    0% {
        transform: translateY(570px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes INFO_SUB_VIDEO-SHOW {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes INFO_SUB-HIDE {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(570px);
        opacity: 0;
    }
}
@keyframes INFO_SUB_VIDEO-HIDE {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes TEXT_PRELOAD-ANIM {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* Панель управления */

@keyframes DASHBOARD-SHOW_CP_FORM {
    0% {
        transform: translateX(120%);
    }
    100% {
        transform: translateX(0%);
    }
}
@keyframes DASHBOARD-HIDE_CP_FORM {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(120%);
    }
}

/* Музыкальный плеер */

/* Музыкальный плеер - Кнопка включени/выключения */

@keyframes PLAYER_S_B-SHOW {
    0% {
        opacity: 0;
        filter: blur(1px);
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
        transform: scale(1);
    }
}
@keyframes PLAYER_S_B-HIDE {
    0% {
        opacity: 1;
        filter: blur(0px);
        transform: scale(1);
    }
    100% {
        opacity: 0;
        filter: blur(1px);
        transform: scale(0.5);
    }
}

/* Музыкальный плеер - Смена трека (Обложкаі) */

@keyframes PLAYER_COVER-HIDE {
    0% {
        transform: translateY(0%);
        filter: blur(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(-150%);
        filter: blur(15px);
        opacity: 0;
    }
}

@keyframes PLAYER_COVER-SHOW {
    0% {
        transform: translateY(150%);
        filter: blur(15px);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        filter: blur(0px);
        opacity: 1;
    }
}